import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Typography } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { useRecoilState, useRecoilValue } from 'recoil'
import { selectedPoint, selectedPointDetail } from 'store/buildingStore'
import { currentAnnotation } from 'store/krpanoStore'
import Utils from 'utils'
import Krpano from 'utils/krpano'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'

type Props = {
  setIsOpen: Dispatch<SetStateAction<string>>
  handleCloseModal: () => void
}

export default function ModalDeleteAnnotation({
  setIsOpen,
  handleCloseModal
}: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const { navigate } = useDirectory()

  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const currentSelected = useRecoilValue(currentAnnotation)
  const [, setPointDetail] = useRecoilState(selectedPointDetail)

  const { trigger: deleteAnnotation, isMutating } =
    useMutation(`v1/annotations`)
  const { trigger: deleteOldContent, isMutating: isDeletingOldContent } =
    useMutation('v1/delete-file')

  const handleClose = () => {
    setIsOpen('')
  }

  const handleSubmit = async () => {
    if (isMutating || isDeletingOldContent) return
    const krpano = document.getElementById('embedpano-full') as any
    try {
      if (currentSelected?.memo) {
        await deleteOldContent({
          data: {
            key: Krpano.GetMemoKeyFromPresignedUrl(currentSelected?.memo)
          }
        })
      }
      await deleteAnnotation({
        method: 'delete',
        query: [currentSelected?.id],
        params: {
          building_id: Number(id),
          floor_id: Number(floorId),
          point_id: currentSelectedPoint
        },
        data: null,
        successMessage: t('building.deleted')
      })
      setPointDetail(
        (prev) =>
          prev && {
            ...prev,
            annotations: prev?.annotations.filter(
              (item) => item.id !== currentSelected?.id
            )
          }
      )
      krpano.call(`remove_annotation(${currentSelected?.id});`)
      handleCloseModal()
      handleClose()
    } catch (error) {
      krpano.call(`remove_annotation(${currentSelected?.id});`)
      handleClose()
      Utils.handleErrorNavigate(error, navigate)
    }
  }

  return (
    <Modal
      onCancel={handleClose}
      width={440}
      isEnterable
      onEnter={handleSubmit}
    >
      <Title title={t('common.titleDeleteAnnotation')} bottomMargin="mb-8" />

      <div className="flex flex-col gap-10 text-center">
        <Typography className="text-[14px] leading-[21px]">
          {t('building.deleteConfirmation')}
        </Typography>
        <div className="flex gap-10 justify-center">
          <Button sizing="w-[140px] h-[50px]" onClick={handleClose}>
            {t('building.buttonCancel')}
          </Button>

          <Button
            sizing="w-[140px] h-[50px]"
            className="bg-[#B80808]"
            onClick={handleSubmit}
          >
            {t('common.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
