import { useParams, useSearchParams } from 'react-router-dom'
import useSWR from 'swr'
import { Point } from 'types/building'
import Krpano from 'utils/krpano'

import useViewerKrpano from '../hooks/useViewerKrpano'

export default function ViewerPanorama() {
  const { id } = useParams()
  const {
    currentPov,
    currentViewer,
    setCurrentViewer,
    setCurrentHgap,
    embedpano
  } = useViewerKrpano()

  const [searchParams] = useSearchParams()
  const hlookat = searchParams.get('hlookat')
  const vlookat = searchParams.get('vlookat')
  const fov = searchParams.get('fov')

  // get point detail when select point
  useSWR<Point>(
    currentViewer.selectedFloor && currentViewer.selectedPoint
      ? {
          url: `v1/points/${currentViewer.selectedPoint}`,
          params: {
            building_id: id,
            floor_id: currentViewer?.selectedFloor
          }
        }
      : null,
    {
      onSuccess: (data) => {
        setCurrentViewer((prev) => ({
          ...prev,
          pointDetail: data,
          selectedImage: data.image360?.id
        }))
        if (data.image360) {
          const pov = currentPov || data.initView
          const initProps = {
            imageUrl: data.image360.s3Path,
            previewUrl: data.image360.thumbnail,
            hgap: data.initView.radarHGap,
            h: hlookat ? +hlookat : pov.h,
            v: vlookat ? +vlookat : pov.v,
            z: fov ? +fov : pov.z
          }
          setCurrentHgap(initProps.hgap)
          const annotationList = data.annotations?.filter(
            (item) => !item.imageID || item.imageID === data.image360?.id
          )
          embedpano(initProps, annotationList, data.directions, data.stairways)
        } else {
          Krpano.UnmountPanoViewer()
        }
      }
    }
  )

  return <div id="viewer-pano-container" className="w-full h-full" />
}
