import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { notification } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { Building } from 'models/Building'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  buildingModeState,
  listPointState,
  selectedPoint,
  selectedPointDetail,
  selectedSubImageState,
  setAnnotationModeState
} from 'store/buildingStore'
import { currentAnnotation, currentPovState } from 'store/krpanoStore'
import useSWR from 'swr'
import { Annotation } from 'types/building'
import {
  Direction,
  KrpanoInitProps,
  KrpanoOptions,
  Stairway
} from 'types/krpano'
import Utils from 'utils'
import Krpano from 'utils/krpano'

export default function useKrpano(options: KrpanoOptions) {
  const { id, floorId } = useParams()
  const { t } = useTranslation()
  const { navigate, getBuiildingType } = useDirectory()

  const [newAnnotationPosition, setNewAnnotationPosition] = useState<any>([])

  const [currentSelectedAnnotation, setCurrentSelectedAnnotation] =
    useRecoilState(currentAnnotation)
  const [currentSelectedPoint, setCurrentSelectedPoint] =
    useRecoilState(selectedPoint)
  const [, setCurrentPov] = useRecoilState(currentPovState)
  const [buildingMode, setBuildingMode] = useRecoilState(buildingModeState)
  const [pointDetail, setPointDetail] = useRecoilState(selectedPointDetail)
  const [, setSelectedSetAnnotation] = useRecoilState(setAnnotationModeState)
  const points = useRecoilValue(listPointState)
  const selectedSubImage = useRecoilValue(selectedSubImageState)

  const { data: building } = useSWR<Building>(
    {
      url: `/v1/buildings/by-type?id=${id}&building_type=${getBuiildingType()}`
    },
    {
      revalidateOnMount: false
    }
  )

  const { trigger, isMutating } = useMutation(`v1/annotations`)

  const {
    trigger: updateDirectionPosition,
    isMutating: isUpdateDirectionPosition
  } = useMutation(`v1/directions`)

  const { trigger: getAnnotationDetail, isMutating: isAnnotationMutating } =
    useMutation('v1/annotations')

  // handle event of krpano
  const getMousePosition = () => {
    ;(window as any).get_mouse_position = () => {
      const krpano = document.getElementById('embedpano-full') as any
      if (options?.buildingMode === 'annotation') {
        const h = krpano.get('mouseath')
        const v = krpano.get('mouseatv')
        setNewAnnotationPosition((prev: any) => [...prev, h, v])
        if (!newAnnotationPosition?.length) {
          krpano.call(
            `set(hotspot[temp_circle_style].visible,true);
             set(hotspot[temp_circle_style].ath,${h});
             set(hotspot[temp_circle_style].atv,${v});`
          )
          krpano.call(
            `set(hotspot[temp_polyline_style].visible,true);
             set(hotspot[temp_polyline_style].point[0].ath,${h});
             set(hotspot[temp_polyline_style].point[0].atv,${v});`
          )
        }
        if (newAnnotationPosition?.length === 2) {
          krpano.call(
            `set(hotspot[temp_polyline_style].point[1].ath,${h});
             set(hotspot[temp_polyline_style].point[1].atv,${v});`
          )
          krpano.call(
            `set(hotspot[temp_circle_middle_style].visible,true);
             set(hotspot[temp_circle_middle_style].ath,${h});
             set(hotspot[temp_circle_middle_style].atv,${v});`
          )
          krpano.call(
            `set(hotspot[temp_polyline_style2].visible,true);
             set(hotspot[temp_polyline_style2].point[0].ath,${h});
             set(hotspot[temp_polyline_style2].point[0].atv,${v});`
          )
        }
        if (newAnnotationPosition?.length === 4) {
          krpano.call(
            `set(hotspot[temp_polyline_style2].point[1].ath,${h});
             set(hotspot[temp_polyline_style2].point[1].atv,${v});`
          )
          setCurrentSelectedAnnotation({
            building_id: +(id || 0),
            floor_id: +(floorId || 0),
            point_id: currentSelectedPoint || '',
            head_h: newAnnotationPosition[0],
            head_v: newAnnotationPosition[1],
            mid_h: newAnnotationPosition[2],
            mid_v: newAnnotationPosition[3],
            files: [],
            h,
            v
          })
          setNewAnnotationPosition([])
        }
      }
    }
  }

  // handle redirect to next point
  const handleRedirect = () => {
    ;(window as any).handle_redirect = (
      item: any,
      clickh: any,
      clickv: any
    ) => {
      if (buildingMode.selectedMode !== 'direction') {
        const ponitSelected = points?.find((point) => point.id === item)
        if (ponitSelected?.image360) {
          Krpano.LoadChecking(() => {
            const krpano = document.getElementById('embedpano-full') as any
            const h = krpano?.get('view.hlookat')
            const v = krpano?.get('view.vlookat')
            const z = krpano?.get('view.fov')

            krpano.call(`
            hotspot['arrow-%1'].getcenter(${clickh},${clickv});
            if(${clickv} GT -50 AND ${clickv} LT +50 AND 1000 GT 120, set(${clickv},0));
            set3dtransition("sphere", ${clickh}, ${clickv}, (min(1000,200)), 0, ${
              Number(pointDetail?.initView.radarHGap) -
              Number(ponitSelected.initView.radarHGap)
            }, 0.0, 0.9, easeoutquad);
          `)

            setCurrentPov({
              h:
                Number(h) +
                Number(pointDetail?.initView.radarHGap) -
                Number(ponitSelected.initView.radarHGap),
              v: Number(v),
              z
            })
            setCurrentSelectedPoint(item)
            setBuildingMode((prev) => ({
              ...prev,
              selectedMode: ''
            }))
          })
        } else {
          notification.error({
            key: 'redirect',
            message: '画像が設定されていません'
          })
        }
      }
    }
  }

  // handle update direction position
  const handleUpdatePosition = () => {
    ;(window as any).handle_update_direction = (
      link_id: string,
      h: any,
      v: any
    ) => {
      const krpano = document.getElementById('embedpano-full') as any
      const arrowh = krpano.get(`init_h`)
      const arrowv = krpano.get(`init_v`)
      const hgap = pointDetail?.initView.radarHGap || 0
      const isChange =
        arrowh !== +h && arrowv !== +v && !isUpdateDirectionPosition
      isChange &&
        updateDirectionPosition({
          query: [link_id],
          data: {
            building_id: Number(id),
            floor_id: Number(floorId),
            point_id: currentSelectedPoint,
            h: Krpano.NormalizeHgap(+h) - (hgap > 180 ? 360 - hgap : hgap),
            v: Krpano.NormalizeHgap(+v)
          }
        })
    }
  }

  // handle selected annotation in area panorama
  const handleClickAnnotation = () => {
    ;(window as any).handle_click_annotation = (annoId: string | undefined) => {
      if (!isAnnotationMutating && buildingMode.selectedMode === 'annotation') {
        getAnnotationDetail(
          {
            method: 'get',
            data: {
              building_id: id,
              floor_id: floorId,
              point_id: currentSelectedPoint
            },
            query: [annoId]
          },
          {
            onSuccess: (data) => {
              if (data.set_annotation_id) {
                setSelectedSetAnnotation((prev) => ({
                  ...prev,
                  selectedSetAnnotation: data.set_annotation_id,
                  mode: 'editSetAnnotation',
                  annotationId: annoId
                }))
                return
              }
              setCurrentSelectedAnnotation({
                ...data,
                building_id: +(id || 0),
                floor_id: +(floorId || 0),
                point_id: currentSelectedPoint
              })
            },
            onError: (error) => {
              if (error.response.data.error.type === 'annotation_not_exists') {
                setCurrentSelectedAnnotation(undefined)
                const krpano = document.getElementById('embedpano-full') as any
                krpano.call(`remove_annotation(${annoId})`)
                notification.error({
                  message: t('translation.deletedAnnotationNotice')
                })
              }
              Utils.handleErrorNavigate(error, navigate)
            }
          }
        )
      }
    }
  }

  const getImageID = () => {
    if (!pointDetail?.subImages?.length) {
      return null
    }
    if (selectedSubImage) {
      return selectedSubImage
    }
    return pointDetail.image360?.id
  }

  const handleDragEnd = () => {
    ;(window as any).handle_drag_end = (a_id: string, type?: string) => {
      const krpano = document.getElementById('embedpano-full') as any
      const inith = krpano.get('init_h')
      const initv = krpano.get('init_v')
      const h = krpano.get('drop_h')
      const v = krpano.get('drop_v')
      const isChangeAble = !isMutating && h !== inith && v !== initv
      let annotation: any
      switch (type) {
        case 'head':
          annotation = {
            head_h: h,
            head_v: v
          }
          break
        case 'middle':
          annotation = {
            mid_h: h,
            mid_v: v
          }
          break
        default:
          annotation = {
            h,
            v
          }
          break
      }
      // TODO: handle untrigger when position not change
      isChangeAble &&
        trigger(
          {
            method: 'put',
            data: {
              building_id: Number(id),
              floor_id: Number(floorId),
              point_id: currentSelectedPoint,
              annotation: { ...annotation, id: a_id, imageID: getImageID() }
            }
          },
          {
            onSuccess: () => {
              setPointDetail(
                (prev) =>
                  prev && {
                    ...prev,
                    annotations: prev?.annotations.map((item) =>
                      item.id === a_id ? { ...item, ...annotation } : item
                    )
                  }
              )
            }
          }
        )
    }
  }

  const addPropertiesToKrpano = (
    krpano: any,
    hgap: number,
    annotations?: Annotation[],
    directions?: Direction[],
    stairways?: Stairway[]
  ) => {
    annotations && Krpano.AddAnnotation(krpano, annotations)
    directions && Krpano.AddDirectionArrow(krpano, directions, hgap)
    stairways && Krpano.AddStairway(krpano, stairways)
  }

  const krpanoOnreadyCallback = (
    krpano: any,
    krpanoInitProps: KrpanoInitProps,
    annotations?: Annotation[],
    directions?: Direction[],
    stairways?: Stairway[]
  ) => {
    const { h, v, z, hgap, isLocked } = krpanoInitProps
    const varString = `layer[set_first_view_button].visible=true&view.hlookat=${h}&view.vlookat=${v}&view.fov=${z}`
    krpano.call(
      `loadpano('/krpano/xml-assets/pano_full.xml','${varString}',IGNOREKEEP,BLEND(1));`
    )
    addPropertiesToKrpano(krpano, hgap, annotations, directions, stairways)
    Krpano.TogglePointLock(isLocked)
  }

  const embedpano = (
    krpanoInitProps: KrpanoInitProps,
    annotations?: Annotation[],
    directions?: Direction[],
    stairways?: Stairway[]
  ) => {
    const embedpano = document.getElementById('embedpano-full') as any
    const target = document.getElementById('krpano-container')
    const { imageUrl, previewUrl, h, v, z, hgap, isLocked } = krpanoInitProps
    if (target) {
      const directionList = (directions || [])
        .map((item) => {
          const order = points.find(
            (point) =>
              point.id !== currentSelectedPoint && item.id.includes(point.id)
          )?.order
          return !order ? item : { ...item, target: order }
        })
        .filter((item) => item.target)

      const stairList = stairways?.map((item) => ({
        ...item,
        nextFloorName: building?.floors.find(
          (floor) => floor.id === +item.nextFloorId
        )?.name
      }))

      const displayedImage = pointDetail?.subImages?.find(
        (item) => item.id === selectedSubImage
      )

      if (!embedpano) {
        ;(window as any).embedpano({
          id: 'embedpano-full',
          xml: null,
          target: 'krpano-container',
          html5: 'auto+webgl',
          initvars: {
            url: displayedImage?.s3Path || imageUrl,
            previewurl: displayedImage?.thumbnail || previewUrl
          },
          bgcolor: '#FFFFFF',
          onready: (krpano: any) => {
            krpanoOnreadyCallback(
              krpano,
              krpanoInitProps,
              annotations,
              directionList,
              stairList
            )
          }
        })
      } else {
        embedpano.call(
          `load_pano_image(${
            displayedImage?.s3Path || imageUrl
          },${h},${v},${z},${displayedImage?.thumbnail || previewUrl})`
        )
        addPropertiesToKrpano(
          embedpano,
          hgap,
          annotations,
          directionList,
          stairways
        )
        Krpano.TogglePointLock(isLocked)
      }
    }
  }

  // handle event view around
  getMousePosition()
  handleRedirect()
  handleClickAnnotation()
  handleDragEnd()
  handleUpdatePosition()

  useEffect(() => {
    if (
      buildingMode.selectedMode !== 'annotation' &&
      newAnnotationPosition.length
    ) {
      Krpano.RemoveTempAnnotation()
      setNewAnnotationPosition([])
    }
  }, [buildingMode.selectedMode, newAnnotationPosition.length])

  useEffect(() => {
    return () => {
      Krpano.UnmountPanoFull()
      setBuildingMode({
        selectedMode: '',
        previewMode: false
      })
    }
  }, [setBuildingMode])

  return {
    embedpano,
    currentSelectedPoint,
    currentSelectedAnnotation,
    setCurrentSelectedAnnotation
  }
}
