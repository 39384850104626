import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuill } from 'react-quilljs'
import { useParams } from 'react-router-dom'
import {
  Checkbox,
  Form,
  Input,
  List,
  notification,
  Select,
  Typography
} from 'antd'
import axios from 'axios'
import clsx from 'clsx'
import Const from 'constants/constant'
import useDirectory from 'hooks/useDirectory'
import useGetAnnotationColor from 'hooks/useGetAnnotationColor'
import useMutation from 'hooks/useMutation'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  selectedPointDetail,
  setAnnotationModeState
} from 'store/buildingStore'
import useSWR from 'swr'
import {
  AnnotationType,
  BuildingChildrenFolder,
  BuildingFile,
  ListBuildingFiles,
  SetAnnotation
} from 'types/building'
import Resizer from 'utils/imageResizer'
import Krpano from 'utils/krpano'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'
import ChildrenFolderIcon from 'components/icons/ChildrenFolderIcon'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'
import RightArrowIcon from 'components/icons/RightArrowIcon'

import PreviewFile from '../PreviewFile'

export default function ModalAddSetAnnotation() {
  const { id } = useParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const name = Form.useWatch('name', form)
  const inputLink = Form.useWatch('link', form)
  const inputLinkName = Form.useWatch('linkName', form)
  const { quill, quillRef } = useQuill({
    theme: 'snow',
    modules: Const.QUILL_MODULES,
    formats: Const.QUILL_FORMATS,
    placeholder: '入力してください'
  })

  const { getBuiildingFolderList, getAnnotationList } = useDirectory()
  const { getAnnotationBgColor } = useGetAnnotationColor()

  const stopPropagation = useRef<boolean>(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingContent, setIsLoadingContent] = useState(false)
  const [isAddingFile, setIsAddingFile] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState<BuildingFile[]>([])
  const [selectedChildrenFolder, setSelectedChildrenFolder] = useState<{
    id: AnnotationType
    label: string
  }>()
  const [nextAnnotationType, setNextAnnotationType] = useState<AnnotationType>()
  const [newSetAnnotation, setNewSetAnnotation] = useState<SetAnnotation>({
    linkName: '',
    link: '',
    name: '',
    memo: '',
    files: [],
    type: AnnotationType.ARCHITECTURE
  })
  const [selectedChildFolder, setSelectedChildFolder] = useState<{
    id: number
    name: string
  }>()

  const annotationBgColor = useMemo(
    () => getAnnotationBgColor(newSetAnnotation.type),
    [getAnnotationBgColor, newSetAnnotation.type]
  )

  const [{ selectedSetAnnotation, annotationId }, setSelectedMode] =
    useRecoilState(setAnnotationModeState)
  const pointDetail = useRecoilValue(selectedPointDetail)

  const { trigger: triggerAddSetAnnotation, isMutating: isAddSetAnnotation } =
    useMutation(`v1/buildings/${id}/set-annotations`)
  const { trigger: getPresignedUrl, isMutating: isGettingPresignedUrl } =
    useMutation('v1/annotations/presigned-urls')
  const { trigger: deleteOldContent, isMutating: isDeletingOldContent } =
    useMutation('v1/delete-file')

  const { data: setAnnotationData, isValidating: isValidatingDetail } =
    useSWR<SetAnnotation>(
      selectedSetAnnotation
        ? {
            url: `v1/buildings/${id}/set-annotations/${selectedSetAnnotation}`
          }
        : null,
      {
        onSuccess: (data) => {
          setNewSetAnnotation((prev) => ({ ...prev, ...data }))
          setSelectedMode((prev) => ({ ...prev, setAnnotationDetail: data }))
        }
      }
    )

  const { data: buildingFiles, isValidating: isValidatingBuildingFiles } =
    useSWR<ListBuildingFiles>(
      selectedChildrenFolder && isAddingFile
        ? {
            url: 'v1/buildings/files',
            params: {
              buildingId: id,
              category: selectedChildrenFolder.id
            }
          }
        : null
    )

  const sortedFiles = useMemo(
    () =>
      [...(buildingFiles?.files.files || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [buildingFiles?.files.files]
  )

  const sortedFolders = useMemo(
    () =>
      [...(buildingFiles?.files.folders || [])].sort(
        (a, b) => a.order - b.order
      ),
    [buildingFiles?.files.folders]
  )

  const { data: folderFiles } = useSWR<BuildingChildrenFolder>(
    selectedChildFolder
      ? {
          url: `v1/buildings/${id}/folders/${selectedChildFolder.id}`
        }
      : null
  )

  const sortedFolderFiles = useMemo(
    () =>
      [...(folderFiles?.files || [])].sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [folderFiles?.files]
  )

  const folder = useMemo(
    () =>
      getBuiildingFolderList().find(
        (folder) => folder.id === newSetAnnotation.type
      ),
    [getBuiildingFolderList, newSetAnnotation.type]
  )

  const childrenFolder = useMemo(() => {
    const children = (folder?.children || []).filter(
      (item) => item.label !== '竣工図'
    )
    return [
      ...children,
      { label: 'リンク', id: 40, isFileHolder: true, children: null }
    ]
  }, [folder?.children])

  const annotationList = useMemo(() => getAnnotationList(), [getAnnotationList])

  const annotationsFile: BuildingFile[] = useMemo(() => {
    const filterList = newSetAnnotation?.files?.filter(
      (file) => file.category === selectedChildrenFolder?.id
    )
    if (isValidatingBuildingFiles) return []
    return filterList?.sort((a, b) => a.name.localeCompare(b.name)) || []
  }, [
    isValidatingBuildingFiles,
    newSetAnnotation?.files,
    selectedChildrenFolder?.id
  ])

  const isEmptySetAnnotation = useMemo(
    () =>
      !newSetAnnotation?.files?.length &&
      !newSetAnnotation?.link &&
      !newSetAnnotation?.linkName &&
      !form.getFieldValue('memo'),
    [
      form,
      newSetAnnotation?.files?.length,
      newSetAnnotation?.link,
      newSetAnnotation?.linkName
    ]
  )

  const onFormFocus = () => {
    stopPropagation.current = true
  }

  const onFormBlur = () => {
    stopPropagation.current = false
  }

  const handleBack = () => {
    selectedChildrenFolder && setSelectedChildrenFolder(undefined)
    if (selectedChildrenFolder?.id === 40) {
      if (!inputLink && inputLinkName) {
        setNewSetAnnotation((prev) => prev && { ...prev, linkName: '' })
      }
      if (inputLink) {
        setNewSetAnnotation((prev) => ({
          ...prev,
          link: inputLink,
          linkName: inputLinkName
        }))
      }
    }
  }

  const handleCloseModal = () => {
    if (selectedSetAnnotation) {
      if (annotationId) {
        setSelectedMode({
          selectedSetAnnotation: undefined,
          setAnnotationDetail: undefined,
          annotationId: undefined,
          mode: undefined
        })
        return
      }
      setSelectedMode((prev) => ({
        ...prev,
        setAnnotationDetail: undefined,
        mode: 'listSetAnnotation'
      }))
      return
    }
    setSelectedMode((prev) => ({
      ...prev,
      mode: undefined,
      selectedSetAnnotation: undefined
    }))
  }

  const changeSelectedFolder = (value: any) => {
    if (!isEmptySetAnnotation) {
      setNextAnnotationType(value)
      return
    }
    if (value !== newSetAnnotation.type) {
      setNewSetAnnotation((prev) => ({
        ...prev,
        type: value
      }))
    }
  }

  const handleCloseFileSelectMenu = (clear?: boolean) => {
    if (clear) {
      setSelectedFiles(annotationsFile)
    } else {
      setNewSetAnnotation((prev) => ({
        ...prev,
        files: selectedFiles
      }))
    }
    setIsAddingFile(false)
  }

  const handleSelectFile = (file: BuildingFile) => {
    const index = selectedFiles.findIndex((item) => item.id === file.id)
    if (index === -1) {
      setSelectedFiles((prev) => [
        ...prev,
        { ...file, category: selectedChildrenFolder?.id }
      ])
      return
    }
    setSelectedFiles((prev) => prev.filter((_, i) => index !== i))
  }

  const handleUploadMemo = useCallback(async () => {
    if (selectedSetAnnotation && setAnnotationData?.memo) {
      await deleteOldContent({
        data: { key: Krpano.GetMemoKeyFromPresignedUrl(setAnnotationData.memo) }
      })
    }
    const newQuillInnerHtml = await Resizer.ResizedQuillImages(
      quill?.root.innerHTML || ''
    )
    const file = new Blob([newQuillInnerHtml], {
      type: 'text/plain'
    })
    if (file.size / (1024 * 1024) > 50) {
      notification.error({
        key: 'memoTooLarge',
        message: t('common.validationError.fileTooLarge')
      })
      return
    }
    const data = await getPresignedUrl({
      data: {
        extension: 'txt',
        fileName: 'annotation-content'
      }
    })
    const { key = undefined, presignedUrl = undefined } = {
      ...data?.data?.s3PreSignedLinks[0]
    }
    await axios.put(presignedUrl, file)
    return { key, content: newQuillInnerHtml }
  }, [
    deleteOldContent,
    getPresignedUrl,
    quill?.root.innerHTML,
    selectedSetAnnotation,
    setAnnotationData?.memo,
    t
  ])

  const handleAddSetAnnotation = async () => {
    if (
      isAddSetAnnotation ||
      isGettingPresignedUrl ||
      isDeletingOldContent ||
      isLoading
    )
      return
    setIsLoading(true)
    try {
      const link =
        selectedChildrenFolder?.id === 40 ? inputLink : newSetAnnotation?.link
      const linkName =
        selectedChildrenFolder?.id === 40
          ? inputLinkName
          : newSetAnnotation?.linkName
      let key = ''
      let content = ''
      if (newSetAnnotation.type === AnnotationType.MEMO) {
        const isMemoEmpty = Krpano.IsMemoEmpty(quill?.root.innerHTML || '')
        if (isMemoEmpty) return
        const uploadedResult = await handleUploadMemo()
        key = uploadedResult?.key || ''
        content = uploadedResult?.content || ''
      }
      await triggerAddSetAnnotation({
        method: selectedSetAnnotation ? 'patch' : 'post',
        data: {
          ...newSetAnnotation,
          memo: key,
          name,
          link,
          linkName,
          files: newSetAnnotation?.files?.map((item) => item.id) || []
        },
        query: selectedSetAnnotation ? [selectedSetAnnotation] : []
      })
      setIsLoading(false)
      notification.success({
        message: t('building.saved')
      })
      if (selectedSetAnnotation) {
        const krpano = document.getElementById('embedpano-full') as any
        pointDetail?.annotations.forEach((item) => {
          if (item.set_annotation_id === selectedSetAnnotation) {
            const headH = krpano.get(`hotspot['head-${item.id}'].ath`)
            const headV = krpano.get(`hotspot['head-${item.id}'].atv`)
            const midH = krpano.get(`hotspot['middle-${item.id}'].ath`)
            const midV = krpano.get(`hotspot['middle-${item.id}'].atv`)
            const h = krpano.get(`hotspot['id-${item.id}'].ath`)
            const v = krpano.get(`hotspot['id-${item.id}'].atv`)
            switch (newSetAnnotation.type) {
              case AnnotationType.ARCHITECTURE:
                krpano?.call(`load_style(${item.id}, 'architect')`)
                break
              case AnnotationType.AIR_CONDITION:
                krpano?.call(`load_style(${item.id}, 'aircon')`)
                break
              case AnnotationType.ELECTRICITY:
                krpano?.call(`load_style(${item.id}, 'electricity')`)
                break
              case AnnotationType.EQUIPMENT:
                krpano?.call(`load_style(${item.id}, 'equipment')`)
                break
              case AnnotationType.MEMO:
                krpano.call(`remove_annotation(${item.id})`)
                Krpano.AddAnnotation(krpano, [
                  {
                    ...item,
                    mid_h: midH,
                    mid_v: midV,
                    head_h: headH,
                    head_v: headV,
                    h,
                    v,
                    memo: content,
                    type: 40
                  }
                ])
                break
              default:
                break
            }
          }
        })
      }
      handleCloseModal()
    } catch (error) {
      handleCloseModal()
    }
  }

  const validateAnnotationLink = () => {
    if (selectedChildrenFolder?.id === 40) {
      const link =
        selectedChildrenFolder?.id === 40 ? inputLink : newSetAnnotation?.link
      const linkName =
        selectedChildrenFolder?.id === 40
          ? inputLinkName
          : newSetAnnotation?.linkName

      const pattern: RegExp =
        // eslint-disable-next-line no-useless-escape
        /^(http(s)?:\/\/)?(?:www\.)?[-a-zA-Z0-9…「」｛｝（）＋！ー＆＃＠〜＊＝：；？＜＞、。＄"｜・\-_!+()&*$@":;<>/?,.[\]#|=%~]{1,256}\.[a-zA-Z0-9…「」｛｝（）＋！ー＆＃＠〜＊＝：；？＜＞、。＄"｜・\-_!+()&*$@":;<>/?,.[\]#|=%~]{1,6}\b(?:[-a-zA-Z0-9…「」｛｝（）＋！ー＆＃＠〜＊＝：；？＜＞、。＄"｜・\-_!+()&*$@":;<>/?,.[\]#|=%~]*)$/
      const regexNonSpecialCha: RegExp =
        // eslint-disable-next-line no-irregular-whitespace
        /^[\p{L}\d０-９ 　「」｛｝（）＋！ー＆＃＠〜＊＝：；？＜＞、。＄"｜・\-_!+()&*$@":;<>/?,.[\]#|=]+$/gu

      const isLinkInvalid =
        link && (!pattern.test(link.trim()) || link.trim().length > 500)

      const isNameInvalid =
        linkName &&
        (!regexNonSpecialCha.test(linkName.trim()) ||
          linkName.trim().length > 500)

      if (isLinkInvalid || isNameInvalid) {
        form.validateFields(['link', 'linkName'])
        return
      }
      if (!link?.trim()) {
        form.setFields([
          {
            name: 'link',
            errors: [t('translation.annotationLinkRequired')]
          }
        ])
        return
      }
    }

    handleAddSetAnnotation()
  }

  const handleSubmit = () => {
    form
      .validateFields(['name'])
      .then(() => {
        if (newSetAnnotation.type === AnnotationType.MEMO) {
          handleAddSetAnnotation()
        } else {
          validateAnnotationLink()
        }
      })
      .catch(() => {})
  }

  const handleEnterSubmit = () => {
    if (stopPropagation.current) return
    handleSubmit()
  }

  const handleCancelChangeType = () => {
    setNextAnnotationType(undefined)
  }

  const handleConfirmChangeType = () => {
    if (nextAnnotationType) {
      setNewSetAnnotation((prev) => ({
        ...prev,
        linkName: '',
        files: [],
        link: '',
        memo: '',
        type: nextAnnotationType
      }))
      setNextAnnotationType(undefined)
    }
  }

  const handleFetchContent = useCallback(async () => {
    if (
      newSetAnnotation.type === AnnotationType.MEMO &&
      setAnnotationData?.memo &&
      quill
    ) {
      setIsLoadingContent(true)
      const textContent = await Krpano.FetchMemoContent(
        setAnnotationData.memo || ''
      )
      quill.root.innerHTML = textContent
      setIsLoadingContent(false)
    }
  }, [newSetAnnotation.type, setAnnotationData?.memo, quill])

  useEffect(() => {
    setSelectedFiles(newSetAnnotation?.files || [])
  }, [newSetAnnotation.files])

  useEffect(() => {
    if (setAnnotationData?.name && !isAddingFile && !isValidatingDetail) {
      form.setFieldValue('name', setAnnotationData.name)
    }
  }, [form, isAddingFile, isValidatingDetail, setAnnotationData?.name])

  useEffect(() => {
    handleFetchContent()
  }, [handleFetchContent])

  return (
    <>
      <Modal
        width="840px"
        rootClassName="shared-permission-modal"
        isEnterable
        onEnter={handleEnterSubmit}
      >
        <Spinner spinning={isValidatingBuildingFiles || isValidatingDetail}>
          {isAddingFile && (
            <>
              <Title
                title={`${folder?.label}${'　'}${selectedChildrenFolder?.label}`}
                height={30}
              />
              <div className="h-[360px] overflow-auto">
                {!isValidatingBuildingFiles && selectedChildFolder && (
                  <>
                    <div
                      className="h-10 flex gap-5 cursor-pointer border-0 border-b border-solid border-[#EEEFF2]"
                      onClick={() => setSelectedChildFolder(undefined)}
                    >
                      <div className="my-auto">
                        <LeftArrowIcon color="#021120" />
                      </div>
                      <div className="text-[#021220] text-[18px] leading-[16px] font-medium mt-[10px]">
                        {selectedChildFolder.name}
                      </div>
                    </div>
                    {sortedFolderFiles.map((item, index) => (
                      <div
                        key={item.id}
                        className={`grid grid-cols-[auto_auto_1fr] items-center gap-5 px-5 min-h-[60px] cursor-pointer border-0 border-b ${
                          index === 0 ? 'border-t' : ''
                        } border-solid border-[#EEEFF2]`}
                        onClick={() => handleSelectFile(item)}
                      >
                        <Checkbox
                          checked={Boolean(
                            selectedFiles.find((file) => file.id === item.id)
                          )}
                        />
                        <PreviewFile file={item} />
                        <Typography className="text-[#021120] leading-[23.17px] font-medium">
                          {item.name}
                        </Typography>
                      </div>
                    ))}
                  </>
                )}
                {!isValidatingBuildingFiles && !selectedChildFolder && (
                  <>
                    {sortedFolders.map((item) => (
                      <div
                        key={item.id}
                        className="h-[60px] flex gap-10 items-center px-10 border-0 border-b border-solid border-[#EEEFF2] cursor-pointer"
                        onClick={() =>
                          setSelectedChildFolder({
                            id: item.id,
                            name: item.name
                          })
                        }
                      >
                        <ChildrenFolderIcon />
                        <div className="text-base text-[#021120]">
                          {item.name}
                        </div>
                      </div>
                    ))}
                    {sortedFiles.map((item, index) => (
                      <div
                        key={item.id}
                        className={clsx(
                          'grid grid-cols-[auto_auto_1fr] items-center gap-5 px-5 min-h-[60px] cursor-pointer border-0 border-b border-solid border-[#EEEFF2]',
                          { 'border-t': index === 0 }
                        )}
                        onClick={() => handleSelectFile(item)}
                      >
                        <Checkbox
                          checked={Boolean(
                            selectedFiles.find((file) => file.id === item.id)
                          )}
                        />
                        <PreviewFile file={item} />
                        <Typography className="text-[#021120] leading-[23.17px] font-medium">
                          {item.name}
                        </Typography>
                      </div>
                    ))}
                  </>
                )}
              </div>

              <div className="flex w-full pt-5 mt-7 justify-center gap-10 border-0 border-t border-solid border-[#EEEFF2]">
                <Button
                  sizing="w-[140px] h-[50px]"
                  onClick={() => handleCloseFileSelectMenu(true)}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  sizing="w-[140px] h-[50px]"
                  className="theme-bg-primary"
                  onClick={() => handleCloseFileSelectMenu()}
                >
                  {t('translation.buttonSelect')}
                </Button>
              </div>
            </>
          )}

          {!isAddingFile && !isValidatingDetail && (
            <>
              <Title height={28.96} title={t('building.addSetAnnotation')} />
              <div className="flex flex-col w-[45rem] gap-[30px]">
                <div className="flex gap-5 items-center">
                  <Typography className="font-bold text-[14px] leading-[14px] text-[#021120] w-[90px]">
                    {t('building.setAnnotationName')}
                  </Typography>
                  <Form form={form} onFocus={onFormFocus} onBlur={onFormBlur}>
                    <Form.Item
                      name="name"
                      className="mb-0 thin-error-message"
                      rules={[
                        {
                          validator:
                            Validators.keywordsAndLengthCustomValidator(
                              t,
                              100,
                              true,
                              true
                            )
                        }
                      ]}
                    >
                      <Input
                        className="w-60 h-[38px]"
                        placeholder="入力してください"
                      />
                    </Form.Item>
                  </Form>
                </div>

                {(!selectedChildrenFolder ||
                  newSetAnnotation.type === AnnotationType.MEMO) && (
                  <div className="flex gap-5 items-center">
                    <Typography className="font-bold text-[14px] leading-[14px] text-[#021120] w-[90px]">
                      {t('building.annotationType')}
                    </Typography>
                    <Select
                      value={newSetAnnotation.type}
                      onSelect={changeSelectedFolder}
                      options={annotationList}
                      suffixIcon={<DropdownIconCustom colorIcon="#000000" />}
                      style={{
                        width: 240,
                        fontSize: 14,
                        color: '#021120'
                      }}
                    />
                  </div>
                )}

                {newSetAnnotation.type === AnnotationType.MEMO ? (
                  <Spinner spinning={isLoadingContent}>
                    <div className="pt-[30px] px-[110px] h-[350px] mb-10">
                      <div
                        ref={quillRef}
                        className="quill-custom-editor overflow-hidden [&>#editor-resizer>.toolbar]:!hidden [&>.ql-editor]:pb-10"
                      />
                    </div>
                  </Spinner>
                ) : (
                  <div className="grow">
                    <div
                      className={clsx(
                        'flex h-[50px] items-center pl-6',
                        annotationBgColor,
                        {
                          'cursor-pointer gap-5': selectedChildrenFolder
                        }
                      )}
                      onClick={handleBack}
                    >
                      {selectedChildrenFolder && <LeftArrowIcon size={20} />}
                      <Typography className="font-bold leading-[23.17px] text-white">
                        {selectedChildrenFolder
                          ? selectedChildrenFolder.label
                          : folder?.label}
                      </Typography>
                    </div>
                    {selectedChildrenFolder?.id === 40 && (
                      <Form
                        form={form}
                        labelCol={{
                          span: 5
                        }}
                        labelAlign="left"
                        colon={false}
                        className="pl-[31px] pr-[50px] pt-[36px] pb-[188px]"
                        onFocus={onFormFocus}
                        onBlur={onFormBlur}
                        initialValues={newSetAnnotation}
                      >
                        <Form.Item
                          name="link"
                          label={
                            <div className="font-bold text-base h-[38px] flex items-center">
                              {t('building.inputURLAnnotation')}
                            </div>
                          }
                          rules={[
                            {
                              validator: Validators.linkAnnotationValidator(t)
                            }
                          ]}
                        >
                          <Input
                            placeholder="https://"
                            className="h-[38px] text-sm"
                          />
                        </Form.Item>

                        <Form.Item
                          name="linkName"
                          label={
                            <div className="font-bold text-base h-[38px] leading-6 flex items-center">
                              {t('building.inputNameAnnotation')}
                            </div>
                          }
                          rules={[
                            {
                              validator:
                                Validators.keywordsAndLengthCustomValidator(
                                  t,
                                  500,
                                  undefined,
                                  true
                                )
                            }
                          ]}
                        >
                          <Input
                            className="text-sm h-[38px]"
                            placeholder={t('common.floor.placeHolder')}
                          />
                        </Form.Item>
                      </Form>
                    )}

                    {selectedChildrenFolder?.id !== 40 &&
                      selectedChildrenFolder && (
                        <>
                          <List
                            style={{
                              height: 248,
                              overflow: 'auto'
                            }}
                            dataSource={annotationsFile}
                            renderItem={(item) => (
                              <List.Item className="flex gap-5 !justify-start !pl-[10px] min-h-[60px] cursor-pointer">
                                <div className="w-fit">
                                  <PreviewFile file={item} />
                                </div>
                                <Typography className="text-[#021120] leading-[23.17px] font-medium">
                                  {item.name}
                                </Typography>
                              </List.Item>
                            )}
                          />
                          <Button
                            sizing={`w-[300px] h-[60px] m-auto mt-[40px] ${annotationBgColor}`}
                            onClick={() => setIsAddingFile(true)}
                          >
                            {t('building.selectFile')}
                          </Button>
                        </>
                      )}

                    {selectedChildrenFolder?.id !== 40 &&
                      !selectedChildrenFolder && (
                        <List
                          style={{
                            height: 300,
                            overflow: 'auto'
                          }}
                          dataSource={childrenFolder}
                          renderItem={(item: any) => (
                            <List.Item
                              className="flex !pl-[10px] h-[60px] !pr-5 cursor-pointer"
                              onClick={() =>
                                setSelectedChildrenFolder({
                                  id: item.id,
                                  label: item.label
                                })
                              }
                            >
                              <Typography className="text-[#021120] leading-[23.17px] font-medium">
                                {item.label}
                              </Typography>
                              <RightArrowIcon color="#3B3C46" size={24} />
                            </List.Item>
                          )}
                        />
                      )}
                  </div>
                )}
              </div>

              <div className="flex w-full items-end pt-5 pr-[30px] mt-5 gap-10 border-0 border-t border-solid border-[#EEEFF2]">
                {selectedSetAnnotation && (
                  <Button
                    sizing="w-[140px] h-[50px]"
                    className="bg-[#B80808]"
                    onClick={() =>
                      setSelectedMode((prev) => ({
                        ...prev,
                        mode: 'deleteSetAnnotation'
                      }))
                    }
                  >
                    {t('translation.delete')}
                  </Button>
                )}
                <Button
                  sizing="w-[140px] h-[50px]"
                  className="ml-auto"
                  onClick={handleCloseModal}
                >
                  {t('common.cancel')}
                </Button>
                <Button
                  type="primary"
                  sizing="w-[140px] h-[50px]"
                  disabled={!name}
                  loading={isAddSetAnnotation || isLoading}
                  onClick={handleSubmit}
                >
                  {t('common.confirm')}
                </Button>
              </div>
            </>
          )}

          {!isAddingFile && isValidatingDetail && <div className="h-[360px]" />}
        </Spinner>
      </Modal>

      {nextAnnotationType ? (
        <Modal
          width={580}
          onCancel={handleCancelChangeType}
          rootClassName="shared-permission-modal"
          isEnterable
          onEnter={handleConfirmChangeType}
        >
          <div className="flex flex-col gap-10 text-center p-5">
            <Typography className="text-[14px] leading-[21px]">
              {t('building.confirmChangeAnnotation')}
            </Typography>

            <div className="flex gap-5 mt-5">
              <Button
                sizing="w-[140px] h-[50px] mx-auto"
                onClick={handleCancelChangeType}
              >
                {t('building.buttonCancel')}
              </Button>
              <Button
                sizing="w-[140px] h-[50px] mx-auto"
                onClick={handleConfirmChangeType}
                type="primary"
              >
                {t('building.buttonSave')}
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  )
}
