import { MouseEvent, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import clsx from 'clsx'
import useDirectory from 'hooks/useDirectory'
import { Building } from 'models/Building'
import { useRecoilState, useRecoilValue } from 'recoil'
import { listPointState } from 'store/buildingStore'
import currentViewerState from 'store/viewerStore'
import useSWR from 'swr'
import { Point } from 'types/building'
import Krpano from 'utils/krpano'

import LazyThumbnail from 'components/atoms/LazyThumbnail'
import RotatedArrowIcon from 'components/icons/RotatedArrowIcon'

type Props = {
  point: Point
}

export default function ViewerPointImages({ point }: Props) {
  const { id: buildingId } = useParams()
  const { id, image360, order } = point
  const { getBuiildingType } = useDirectory()

  const [isExpanded, setIsExpanded] = useState(false)

  const points = useRecoilValue(listPointState)
  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)

  const { data: building } = useSWR<Building>(
    {
      url: `/v1/buildings/by-type?id=${buildingId}&building_type=${getBuiildingType()}`
    },
    {
      revalidateOnMount: false
    }
  )

  const listSubImages = useMemo(
    () =>
      currentViewer.pointDetail?.id === point.id
        ? [...(currentViewer.pointDetail?.subImages || [])].sort(
            (a, b) => Number(a.order) - Number(b.order)
          )
        : [],
    [
      point.id,
      currentViewer.pointDetail?.id,
      currentViewer.pointDetail?.subImages
    ]
  )

  const directionList = useMemo(
    () =>
      currentViewer.pointDetail?.directions
        ?.map((item) => {
          const order = points.find(
            (p) =>
              p.id !== currentViewer.selectedPoint && item.id.includes(p.id)
          )?.order
          return { ...item, target: order }
        })
        .filter((item: any) => item.target),
    [currentViewer.pointDetail?.directions, currentViewer.selectedPoint, points]
  )
  const stairList = useMemo(
    () =>
      currentViewer.pointDetail?.stairways?.map((item) => ({
        ...item,
        nextFloorName: building?.floors.find(
          (floor) => floor.id === +item.nextFloorId
        )?.name
      })),
    [building?.floors, currentViewer.pointDetail?.stairways]
  )

  const handleSelectPoint = () => {
    const panoViewer = document.getElementById('embedpano-viewer') as any
    const isLoadComplete = panoViewer?.get('loadcomplete')
    if (currentViewer.selectedPoint === id) {
      setCurrentViewer((prev) => ({
        ...prev,
        selectedImage: currentViewer.pointDetail?.image360?.id
      }))
      panoViewer.call(
        `
        set(loadcomplete,false);
        remove_all_hotspot();
        image.reset();
        set(image.sphere.url, ${currentViewer.pointDetail?.image360?.s3Path});
        set(preview.url, ${currentViewer.pointDetail?.image360?.thumbnail});
        loadpanoimage(MERGE | KEEPLOOKAT | KEEPHOTSPOTS , BLEND(0.9, easeoutquad));
        `
      )
      const annotations = currentViewer.pointDetail?.annotations.filter(
        (item) => {
          return (
            !item.imageID ||
            item.imageID === currentViewer.pointDetail?.image360?.id
          )
        }
      )
      annotations && Krpano.AddAnnotation(panoViewer, annotations)
      directionList &&
        Krpano.AddDirectionArrow(
          panoViewer,
          directionList,
          currentViewer.pointDetail?.initView.radarHGap || 0
        )
      stairList && Krpano.AddStairway(panoViewer, stairList)
      return
    }

    if (isLoadComplete === undefined || isLoadComplete === 'true') {
      setCurrentViewer((prev) => ({
        ...prev,
        selectedPoint: id
      }))
    }
  }

  const handleSelectSubimage = (
    subImageId: number,
    s3Path: string,
    thumbnail: string
  ) => {
    const panoViewer = document.getElementById('embedpano-viewer') as any
    if (currentViewer.selectedPoint !== id) return

    if (panoViewer && panoViewer?.get('loadcomplete') === 'true') {
      setCurrentViewer((prev) => ({ ...prev, selectedImage: subImageId }))
      panoViewer.call(
        `
        set(loadcomplete,false);
        remove_all_hotspot();
        image.reset();
        set(image.sphere.url, ${s3Path});
        set(preview.url, ${thumbnail});
        loadpanoimage(MERGE | KEEPLOOKAT | KEEPHOTSPOTS , BLEND(0.9, easeoutquad));
        `
      )
      const annotations = currentViewer.pointDetail?.annotations.filter(
        (item) => {
          return !item.imageID || item.imageID === subImageId
        }
      )
      annotations && Krpano.AddAnnotation(panoViewer, annotations)
      directionList &&
        Krpano.AddDirectionArrow(
          panoViewer,
          directionList,
          currentViewer.pointDetail?.initView.radarHGap || 0
        )
      stairList && Krpano.AddStairway(panoViewer, stairList)
    }
  }

  const handleToggle = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    if (
      !currentViewer.selectedPoint ||
      currentViewer.selectedPoint !== point.id
    ) {
      setCurrentViewer((prev) => ({ ...prev, selectedPoint: point.id }))
    }
    setIsExpanded((prev) => !prev)
  }

  return (
    <>
      <div
        className={clsx('p-[10px] min-h-[36px]', {
          'theme-bg-sub-primary':
            currentViewer.selectedPoint === id &&
            currentViewer.selectedImage === image360?.id
        })}
        onClick={handleSelectPoint}
      >
        <div
          className={`absolute ${
            image360 ? 'top-[15px] left-[16px]' : 'top-[10px] left-[10px]'
          } z-10 rounded-lg flex bg-[#021120] p-[2px] min-w-[16px]`}
        >
          <div className="m-auto text-[13px] leading-[13px] font-bold text-white">
            {order}
          </div>
        </div>

        {point.isSubImages && (
          <div
            className="absolute top-[15px] left-[35px] z-10 rounded-full flex bg-[#787B82CC] w-[18px] h-[18px]"
            onClick={handleToggle}
          >
            <RotatedArrowIcon className="m-auto" />
          </div>
        )}

        {image360 && (
          <LazyThumbnail
            url={image360.thumbnail || image360.s3Path}
            name={id}
          />
        )}
      </div>
      {isExpanded &&
        listSubImages.map((item) => (
          <div
            key={item.id}
            className={clsx('p-[10px] min-h-[36px]', {
              'theme-bg-sub-primary': currentViewer.selectedImage === item.id
            })}
            onClick={() =>
              handleSelectSubimage(item.id, item.s3Path, item.thumbnail)
            }
          >
            <LazyThumbnail url={item.thumbnail} name={id} />
          </div>
        ))}
    </>
  )
}
