import { Link } from 'react-router-dom'
import { Empty, Typography } from 'antd'
import dayjs from 'dayjs'
import useSWR from 'swr'
import { ListNotification } from 'types/notification'

import Spinner from 'components/atoms/Spinner'
import HeaderTransparent from 'components/organisms/Header/HeaderTransparent'

export default function NewsFeedPage() {
  const { data: notifications, isValidating: isValidatingList } =
    useSWR<ListNotification>({
      url: '/v1/notifications-public'
    })

  return (
    <div className="min-h-screen grid grid-rows-[auto_1fr_auto] bg-[#F9FBFF]">
      <HeaderTransparent />

      <div className="grow h-full flex z-10">
        <div className="bg-white rounded-[10px] m-auto w-[80vw] max-w-[800px] py-4 overflow-y-auto shadow-[0_4px_14px_0_#00000040]">
          <Spinner spinning={isValidatingList}>
            <div
              className={`ml-5 mr-2 h-32 py-3 ${
                notifications?.edges.length
                  ? ' overflow-y-auto custom-scrollbar'
                  : ''
              }`}
            >
              {!isValidatingList &&
                !!notifications?.edges.length &&
                notifications.edges.map((item, index) => (
                  <Link
                    key={item.id}
                    to={`/notifications/${item.id}`}
                    target="_blank"
                  >
                    <div className="grid grid-cols-[auto_auto_1fr] items-center cursor-pointer py-2 pr-2">
                      <div className="w-10 text-[#B80808] text-xs">
                        {index === 0 && 'NEW'}
                      </div>
                      <div className="mr-12 text-black text-sm">
                        {item.createdAt &&
                          dayjs(item.createdAt).format('YYYY/MM/DD')}
                      </div>
                      <Typography.Text
                        ellipsis={{
                          tooltip: item.title
                        }}
                        className="text-black text-sm"
                      >
                        {item.title}
                      </Typography.Text>
                    </div>
                  </Link>
                ))}
              {!isValidatingList && !notifications?.edges.length && (
                <div className="">
                  <Empty description="" />
                  <div className="text-xs text-center">データがありません</div>
                </div>
              )}
            </div>
          </Spinner>
        </div>
      </div>

      <div className="ml-auto px-7 py-5">
        <img src="/logo/filled_footer.png" alt="test" className="h-[50px]" />
      </div>
    </div>
  )
}
