import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import { Empty, Typography } from 'antd'
import Const from 'constants/constant'
import PreviewFile from 'pages/building/panoramas/components/PreviewFile'
import { useRecoilState } from 'recoil'
import { currentAnnotation } from 'store/krpanoStore'
import useSWR from 'swr'
import { AnnotationType, ListBuildingFiles } from 'types/building'
import Krpano from 'utils/krpano'

import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import CloseIcon from 'components/icons/CloseIcon'
import RightArrowIcon from 'components/icons/RightArrowIcon'

import ViewerFilePreview from '../ViewerFilePreview'

import BCPAnnotationHeader from './BCPAnnotationHeader'

import 'quill/dist/quill.snow.css'

export default function BCPViewerAnnotationModal() {
  const { t } = useTranslation()
  const { id } = useParams()
  const qlContainerRef = useRef<HTMLDivElement>(null)

  const [selectedTabs, setSelectedTabs] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [previewFileIndex, setPreviewFileIndex] = useState<number>()
  const [isLoadingContent, setIsLoadingContent] = useState(false)

  const [currentSelected, setCurrentSelected] =
    useRecoilState(currentAnnotation)

  const { data: bcpCustomFolders } = useSWR({
    url: `v1/bcp/buildings/${id}/folders`
  })

  const listTabs = useMemo((): { value: number; label: string }[] => {
    if (currentSelected) {
      if (Object.values(AnnotationType).includes(currentSelected?.type || '')) {
        return Const.BCP_ANNOTATION_TABS.map((item) => ({
          value: (currentSelected?.type || 0) + item.value,
          label: item.label
        }))
      }
      return (
        bcpCustomFolders
          ?.find((item: any) => item.categoryId === currentSelected?.type)
          ?.childFolders?.map((item: any) => ({
            value: item.categoryId,
            label: item.name
          })) || []
      )
    }
    return []
  }, [bcpCustomFolders, currentSelected])

  const selectedTabIndex = useMemo(
    () => listTabs.findIndex((item) => item.value === selectedTabs),
    [listTabs, selectedTabs]
  )

  const selectedCustomFolderLabel = useMemo(
    () =>
      bcpCustomFolders?.find(
        (item: any) => item.categoryId === currentSelected?.type
      )?.name || '',
    [bcpCustomFolders, currentSelected?.type]
  )

  const { data, isValidating } = useSWR<ListBuildingFiles>(
    currentSelected?.type && selectedTabs
      ? {
          url: 'v1/buildings/files',
          params: {
            buildingId: id,
            category: selectedTabs
          }
        }
      : null
  )

  const filteredTabFileList = useMemo(
    () =>
      currentSelected?.files?.filter(
        (item) => data?.files.files.find((file) => file.id === item.id)
      ) || [],
    [currentSelected?.files, data?.files.files]
  )

  const filteredSavedFiles = useMemo(() => {
    if (selectedCategory === 'video') {
      return filteredTabFileList.filter(
        (item) => item.extension === 'mp4' || item.extension === 'mov'
      )
    }
    if (selectedCategory === 'image') {
      return filteredTabFileList.filter(
        (item) =>
          item.extension === 'jpg' ||
          item.extension === 'png' ||
          item.extension === 'jpeg'
      )
    }
    if (selectedCategory === 'pdf') {
      return filteredTabFileList.filter((item) => item.extension === 'pdf')
    }
    return []
  }, [filteredTabFileList, selectedCategory])

  const displayText = useMemo(() => {
    switch (selectedTabIndex) {
      case 1:
        return {
          link: currentSelected?.link_occurence || '',
          name: currentSelected?.name_occurence || '',
          memo: currentSelected?.memo_occurence || ''
        }
      case 2:
        return {
          link: currentSelected?.link_recovery || '',
          name: currentSelected?.name_recovery || '',
          memo: currentSelected?.memo_recovery || ''
        }
      default:
        return {
          link: currentSelected?.link || '',
          name: currentSelected?.name || '',
          memo: currentSelected?.memo || ''
        }
    }
  }, [currentSelected, selectedTabIndex])

  const handleCloseModal = () => {
    setCurrentSelected(undefined)
  }

  const getUnderlinePosition = useCallback(
    (width: number) => {
      const leftOffset = selectedTabIndex * width
      return leftOffset
    },
    [selectedTabIndex]
  )

  const handleFetchContent = useCallback(async () => {
    if (selectedCategory === 'memo' && displayText.memo) {
      setIsLoadingContent(true)
      const textContent = await Krpano.FetchMemoContent(displayText.memo)
      if (qlContainerRef.current) {
        qlContainerRef.current.innerHTML = textContent
      }
      setIsLoadingContent(false)
    }
  }, [displayText.memo, selectedCategory])

  useEffect(() => {
    if (listTabs.length) {
      setSelectedTabs(listTabs[0].value)
    }
  }, [listTabs])

  useEffect(() => {
    handleFetchContent()
  }, [handleFetchContent])

  return (
    <>
      <Modal
        width="840px"
        open={previewFileIndex === undefined}
        rootClassName="annotation-detail-modal"
      >
        <div
          className="absolute top-5 right-5 cursor-pointer"
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </div>
        {currentSelected?.isMutating ? (
          <div className="h-[560px]" />
        ) : (
          <>
            <div className="mr-20">
              <BCPAnnotationHeader
                type={currentSelected?.type}
                label={selectedCustomFolderLabel}
              />
            </div>
            <div className="mt-6 h-[500px] grid grid-rows-[auto_1fr]">
              {selectedCategory ? (
                <div className="flex relative bg-[#F9FBFF] h-10 mt-[10px]">
                  {listTabs.map((item, index) => (
                    <div
                      key={item.value}
                      className={`flex w-[168px] h-10 cursor-pointer ${
                        index === Const.BCP_ANNOTATION_TABS.length - 1
                          ? ''
                          : 'border-0 border-r border-solid border-white'
                      }`}
                      onClick={() => setSelectedTabs(item.value)}
                    >
                      <div
                        className={`text-base ${
                          selectedTabs === item.value
                            ? 'text-[#17412D]'
                            : 'text-[#021120]'
                        } m-auto`}
                      >
                        {t(item.label)}
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      position: 'absolute',
                      left: getUnderlinePosition(168),
                      bottom: 0
                    }}
                    className="transition-all w-[168px] h-[2px] bg-[#247F52]"
                  />
                </div>
              ) : (
                <div className="flex relative bg-[#247F52] h-[50px] mt-[10px]">
                  {listTabs.map((item) => (
                    <div
                      key={item.value}
                      className="flex w-20 h-[50px] cursor-pointer"
                      onClick={() => setSelectedTabs(item.value)}
                    >
                      <div className="text-base text-white m-auto">
                        {t(item.label)}
                      </div>
                    </div>
                  ))}
                  {listTabs.length ? (
                    <div
                      style={{
                        position: 'absolute',
                        left: getUnderlinePosition(80),
                        bottom: 0
                      }}
                      className="transition-all w-20 h-[5px] bg-[#A1C6B4]"
                    />
                  ) : null}
                </div>
              )}
              <div className="overflow-auto">
                {selectedCategory ? (
                  <>
                    <div
                      className="ml-5 my-[23px] text-[20px] leading-[1] font-bold cursor-pointer"
                      onClick={() => setSelectedCategory('')}
                    >
                      {t('translation.toppu')}＞
                      {t(`translation.${selectedCategory}`)}
                    </div>
                    {selectedCategory !== 'link' &&
                    selectedCategory !== 'memo' ? (
                      <div className="flex flex-col gap-[23px] overflow-auto">
                        {!isValidating && filteredSavedFiles.length
                          ? filteredSavedFiles
                              .sort((a, b) => a.name.localeCompare(b.name))
                              .map((file, index) => (
                                <div
                                  key={file.id}
                                  className="grid grid-cols-[auto_1fr] items-center gap-[10px] px-[17px] cursor-pointer"
                                  onClick={() => setPreviewFileIndex(index)}
                                >
                                  <PreviewFile file={file} size="large" />
                                  <Typography.Text
                                    ellipsis={{
                                      tooltip: file.name
                                    }}
                                  >
                                    {file.name}
                                  </Typography.Text>
                                </div>
                              ))
                          : null}

                        {!isValidating && !filteredSavedFiles.length ? (
                          <Empty />
                        ) : null}
                      </div>
                    ) : null}
                    {selectedCategory === 'link' ? (
                      <div className="mx-10 whitespace-normal">
                        {displayText.link ? (
                          <Link
                            to={displayText.link}
                            target="_blank"
                            className="text-[#007AFF] underline hover:underline"
                          >
                            {displayText.name || displayText.link}
                          </Link>
                        ) : null}
                      </div>
                    ) : null}
                    {selectedCategory === 'memo' ? (
                      <Spinner spinning={isLoadingContent}>
                        <div className="ql-container ql-snow mx-10 h-[300px]">
                          <div ref={qlContainerRef} className="ql-editor" />
                        </div>
                      </Spinner>
                    ) : null}
                  </>
                ) : null}

                {!selectedCategory
                  ? Const.BCP_ANNOTATION_TAB_CATEGORIES.map((item) => (
                      <div
                        key={item.value}
                        className="flex items-center justify-between h-[60px] border-0 border-b border-solid border-[#d9d9d9] cursor-pointer"
                        onClick={() => setSelectedCategory(item.value)}
                      >
                        <div className="text-base text-[#021120] font-medium ml-[10px]">
                          {t(item.label)}
                        </div>
                        <div className="mr-5">
                          <RightArrowIcon color="#021120" />
                        </div>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </>
        )}
      </Modal>
      {previewFileIndex !== undefined && (
        <ViewerFilePreview
          fileIndex={previewFileIndex}
          file={filteredSavedFiles[previewFileIndex]}
          maxLength={filteredSavedFiles.length}
          setPreviewFileIndex={setPreviewFileIndex}
        />
      )}
    </>
  )
}
