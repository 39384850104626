import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuill } from 'react-quilljs'
import { useParams } from 'react-router-dom'
import { Form, notification } from 'antd'
import axios from 'axios'
import Const from 'constants/constant'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  selectedPoint,
  selectedPointDetail,
  selectedSubImageState
} from 'store/buildingStore'
import { currentAnnotation } from 'store/krpanoStore'
import { AnnotationType } from 'types/building'
import Utils from 'utils'
import Resizer from 'utils/imageResizer'
import Krpano from 'utils/krpano'

import useDirectory from './useDirectory'
import useMutation from './useMutation'

export default function useAnnotation() {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const [form] = Form.useForm()
  const { navigate, getBuiildingFolderList } = useDirectory()
  const {
    quill,
    quillRef,
    Quill: QuillClass
  } = useQuill({
    theme: 'snow',
    modules: Const.QUILL_MODULES,
    formats: Const.QUILL_FORMATS,
    placeholder: '入力してください'
  })

  const selectedSubImage = useRecoilValue(selectedSubImageState)
  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const [currentSelected, setCurrentSelected] =
    useRecoilState(currentAnnotation)
  const [pointDetail, setPointDetail] = useRecoilState(selectedPointDetail)

  const [isLoading, setIsLoading] = useState(false)

  const [selectedFolder, setSelectedFolder] = useState<AnnotationType>(
    currentSelected?.type || AnnotationType.ARCHITECTURE
  )
  const [selectedChildrenFolder, setSelectedChildrenFolder] = useState<{
    id: AnnotationType
    label: string
  }>()
  const isDataExistsAnnotation =
    !currentSelected?.files?.length &&
    !currentSelected?.link &&
    !currentSelected?.name

  const { trigger, isMutating } = useMutation(`v1/annotations`)
  const { trigger: getPresignedUrl, isMutating: isGettingPresignedUrl } =
    useMutation('v1/annotations/presigned-urls')
  const { trigger: deleteOldContent, isMutating: isDeletingOldContent } =
    useMutation('v1/delete-file')

  const buildingFolder = useMemo(
    () => getBuiildingFolderList(),
    [getBuiildingFolderList]
  )
  const dataSource = useMemo(
    () => buildingFolder.find((folder) => folder.id === selectedFolder),
    [buildingFolder, selectedFolder]
  )
  const childrenFolder = useMemo(() => {
    const children = (dataSource?.children || []).filter(
      (item) => item.label !== '竣工図'
    )
    return [
      ...children,
      { label: 'リンク', id: 40, isFileHolder: true, children: null }
    ]
  }, [dataSource?.children])

  const hotspotLoadStyle = (krpano: any, data: any) => {
    krpano.call(`remove_annotation(${data.id})`)
    Krpano.AddAnnotation(krpano, [data])
  }

  const createNewAnnotation = (krpano: any, data: any) => {
    krpano.call('hide_temp_hotspot()')
    Krpano.AddAnnotation(krpano, [data])
  }

  const handleBack = () => {
    selectedChildrenFolder && setSelectedChildrenFolder(undefined)
    if (
      selectedChildrenFolder?.id === 40 &&
      !currentSelected?.link &&
      currentSelected?.name
    ) {
      form.setFieldValue('name', '')
      setCurrentSelected((prev) => ({ ...prev, name: '' }))
    }
  }

  const handleCloseModal = () => {
    notification.destroy('placingAnnotation')
    if (selectedChildrenFolder) {
      setSelectedChildrenFolder(undefined)
    }
    if (!currentSelected?.id) {
      Krpano.RemoveTempAnnotation()
    }
    setCurrentSelected(undefined)
  }

  const getImageID = () => {
    if (!pointDetail?.subImages?.length) {
      return null
    }
    const isViewingSubImage = pointDetail?.subImages?.find(
      (item) => item.id === selectedSubImage
    )
    if (isViewingSubImage) {
      return selectedSubImage
    }
    return pointDetail.image360?.id
  }

  const handleAddEditAnnotation = async (memo?: string) => {
    const krpano = document.getElementById('embedpano-full') as any
    try {
      if (currentSelected?.memo) {
        await deleteOldContent({
          data: {
            key: Krpano.GetMemoKeyFromPresignedUrl(currentSelected?.memo)
          }
        })
      }
      const convertedValue = {
        ...currentSelected,
        link: (currentSelected?.link || '').trim(),
        name: (currentSelected?.name || '').trim(),
        memo: memo || null,
        type: selectedFolder,
        files:
          currentSelected?.files &&
          currentSelected?.files.map((item) => item.id),
        imageID: getImageID()
      }
      const { data } = await trigger({
        method: 'put',
        data: {
          building_id: Number(id),
          floor_id: Number(floorId),
          point_id: currentSelectedPoint,
          annotation: convertedValue
        }
      })
      if (!currentSelected?.id) {
        setPointDetail(
          (item) =>
            item && {
              ...item,
              annotations: [...(item?.annotations || []), data]
            }
        )
        createNewAnnotation(krpano, data)
      } else {
        setPointDetail(
          (item) =>
            item && {
              ...item,
              annotations: item.annotations.map((anno) =>
                anno.id === currentSelected.id ? data : anno
              )
            }
        )
        hotspotLoadStyle(krpano, data)
      }
      notification.success({
        message: t('building.saved')
      })
      setIsLoading(false)
      handleCloseModal()
    } catch (error) {
      krpano.call('hide_temp_hotspot()')
      notification.destroy('placingAnnotation')
      setIsLoading(false)
      Utils.handleErrorNavigate(error, navigate)
    }
  }

  const validateAnnotationLink = () => {
    if (selectedChildrenFolder?.id === 40) {
      const link = currentSelected?.link
      const name = currentSelected?.name

      // TODO: regex for non special characters
      const pattern: RegExp =
        // eslint-disable-next-line no-useless-escape
        /^(http(s)?:\/\/)?(?:www\.)?[-a-zA-Z0-9…「」｛｝（）＋！ー＆＃＠〜＊＝：；？＜＞、。＄"｜・\-_!+()&*$@":;<>/?,.[\]#|=%~]{1,256}\.[a-zA-Z0-9…「」｛｝（）＋！ー＆＃＠〜＊＝：；？＜＞、。＄"｜・\-_!+()&*$@":;<>/?,.[\]#|=%~]{1,6}\b(?:[-a-zA-Z0-9…「」｛｝（）＋！ー＆＃＠〜＊＝：；？＜＞、。＄"｜・\-_!+()&*$@":;<>/?,.[\]#|=%~]*)$/
      const regexNonSpecialCha: RegExp =
        // eslint-disable-next-line no-irregular-whitespace
        /^[\p{L}\d０-９ 　「」｛｝（）＋！ー＆＃＠〜＊＝：；？＜＞、。＄"｜・\-_!+()&*$@":;<>/?,.[\]#|=]+$/gu

      const isLinkInvalid =
        link && (!pattern.test(link.trim()) || link.trim().length > 500)

      const isNameInvalid =
        name &&
        (!regexNonSpecialCha.test(name.trim()) || name.trim().length > 500)

      if (isLinkInvalid || isNameInvalid) {
        return
      }
      if (!link?.trim()) {
        form.setFields([
          {
            name: 'link',
            errors: [t('translation.annotationLinkRequired')]
          }
        ])
        return
      }
    }
    handleAddEditAnnotation()
  }

  const handleSubmit = async () => {
    if (isMutating || isGettingPresignedUrl || isDeletingOldContent) return
    setIsLoading(true)
    if (selectedFolder === AnnotationType.MEMO) {
      const isMemoEmpty = Krpano.IsMemoEmpty(quill?.root.innerHTML || '')
      if (isMemoEmpty) {
        setIsLoading(false)
        return
      }
      const newQuillInnerHtml = await Resizer.ResizedQuillImages(
        quill?.root.innerHTML || ''
      )
      const file = new Blob([newQuillInnerHtml], {
        type: 'text/plain'
      })
      if (file.size / (1024 * 1024) > 50) {
        notification.error({
          key: 'memoTooLarge',
          message: t('common.validationError.fileTooLarge')
        })
        return
      }
      const data = await getPresignedUrl({
        data: {
          extension: 'txt',
          fileName: 'annotation-content'
        }
      })
      const { key = undefined, presignedUrl = undefined } = {
        ...data?.data?.s3PreSignedLinks[0]
      }
      await axios.put(presignedUrl, file)
      await handleAddEditAnnotation(key)
    } else {
      validateAnnotationLink()
    }
  }

  return {
    form,
    isLoading,
    isDataExistsAnnotation,
    dataSource,
    childrenFolder,
    quill,
    quillRef,
    QuillClass,
    currentSelected,
    setCurrentSelected,
    selectedFolder,
    setSelectedFolder,
    selectedChildrenFolder,
    setSelectedChildrenFolder,
    handleBack,
    handleCloseModal,
    validateAnnotationLink,
    handleSubmit
  }
}
