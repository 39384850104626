import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { FormInstance, Typography } from 'antd'
import useGetAnnotationColor from 'hooks/useGetAnnotationColor'
import { AnnotationType, BuildingFile } from 'types/building'

import LeftArrowIcon from 'components/icons/LeftArrowIcon'

import FolderList from './FolderList'
import LinkSetting from './LinkSetting'

type Props = {
  form: FormInstance
  selectedFolder: AnnotationType
  selectedChildrenFolder: any
  childrenFolder: any
  dataSource: any
  handleBack: () => void
  setSelectedChildrenFolder: any
  setIsAddingFile: Dispatch<SetStateAction<boolean>>
  setExistsFile: Dispatch<SetStateAction<BuildingFile[]>>
}

export default function AnnotationSetting({
  form,
  selectedFolder,
  selectedChildrenFolder,
  childrenFolder,
  setSelectedChildrenFolder,
  dataSource,
  handleBack,
  setIsAddingFile,
  setExistsFile
}: Props) {
  const { getAnnotationBgColor } = useGetAnnotationColor()

  const annotationBgColor = useMemo(
    () => getAnnotationBgColor(selectedFolder),
    [getAnnotationBgColor, selectedFolder]
  )

  return (
    <div className="grow">
      <div
        className={`flex h-[50px] ${annotationBgColor} items-center pl-6 ${
          selectedChildrenFolder ? 'cursor-pointer gap-5' : ''
        }`}
        onClick={handleBack}
      >
        {selectedChildrenFolder && <LeftArrowIcon size={20} />}
        <Typography className="font-bold leading-[23.17px] text-white">
          {selectedChildrenFolder
            ? selectedChildrenFolder.label
            : dataSource?.label}
        </Typography>
      </div>
      {selectedChildrenFolder?.id === 40 ? (
        <LinkSetting form={form} />
      ) : (
        <FolderList
          annotationBgColor={annotationBgColor}
          childrenFolder={childrenFolder}
          selectedChildrenFolder={selectedChildrenFolder}
          setSelectedChildrenFolder={setSelectedChildrenFolder}
          setIsAddingFile={setIsAddingFile}
          setExistsFile={setExistsFile}
        />
      )}
    </div>
  )
}
