import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Input, Row, Select, Space, Typography } from 'antd'
import useDebounce from 'hooks/useDebounce'
import { ListUsers } from 'models/User'
import useSWR from 'swr'
import { OrderByType } from 'types'
import Validators from 'utils/validators'

import DropdownIconCustom from 'components/icons/DropdownIconCustom'

export default function FormItemSelectUser() {
  const { t } = useTranslation()

  const [keywordFindUser, setKeywordFindUser] = useState<string>('')
  const [pageSize, setPageSize] = useState(10)

  const searchDebounce = useDebounce(keywordFindUser, 500)

  const { data: users, isValidating: isValidatingBAList } = useSWR<ListUsers>({
    url: `/v1/users/compact?keyword=${searchDebounce}`,
    params: {
      sort: 'id',
      page: 1,
      page_size: pageSize,
      sortOrder: OrderByType.ascend
    }
  })

  const dropdownRender = (menu: any) => (
    <div className="w-[300px]">
      <Space className="flex justify-between px-3 pt-2 pb-4 w-[300px]">
        <Typography>{t('organization.searchUser')}</Typography>
        <Input
          placeholder="キーワード"
          onChange={(e) => setKeywordFindUser(e.target.value)}
        />
      </Space>
      {menu}
    </div>
  )

  const handleSelectScroll = (e: any) => {
    const { scrollTop, offsetHeight, scrollHeight } = e.target
    if (
      !isValidatingBAList &&
      scrollTop + offsetHeight === scrollHeight &&
      users?.edges.length === pageSize
    ) {
      setPageSize((prev) => prev + 10)
    }
  }

  return (
    <Row className="min-h-[60px] px-5 py-1 border-0 border-b border-solid border-[#eeeff2] flex items-center">
      <Col span={6}>
        <div className="flex items-center gap-3">
          {t('organization.managerId')}

          <div className="flex">
            <div className="text-[#B80808] text-xs">※</div>
            <div className="text-[#B80808] text-xs">
              {t('common.form.required')}
            </div>
          </div>
        </div>
      </Col>
      <Col span={18}>
        <Form.Item
          name="managerId"
          className="mb-0"
          rules={[
            { validator: Validators.keywordValidator(t) },
            {
              required: true,
              message: t('organization.validateBranchAdmin')
            }
          ]}
        >
          <Select
            style={{ maxHeight: '46px', width: '300px' }}
            className="ant-select-selector custom-dropdown"
            placeholder={t('organization.placeholderUserOrganizationID')}
            optionFilterProp="children"
            dropdownRender={dropdownRender}
            onPopupScroll={handleSelectScroll}
            placement="topLeft"
            suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
          >
            {users?.edges.map((item: any) => (
              <Select.Option key={item.id} value={item.id}>
                {`${item.lastName} ${item.firstName}`}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
  )
}
