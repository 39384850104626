import { useTranslation } from 'react-i18next'
import { Form, FormInstance, Input, Row } from 'antd'
import { useRecoilState } from 'recoil'
import { currentAnnotation } from 'store/krpanoStore'
import Validators from 'utils/validators'

type Props = {
  form: FormInstance
}

export default function LinkSetting({ form }: Props) {
  const { t } = useTranslation()

  const [currentSelected, setCurrentSelected] =
    useRecoilState(currentAnnotation)

  const handleChangeNameField = (e: any) => {
    setCurrentSelected((prev) => ({ ...prev, name: e.target.value }))
  }

  const handleChangeLinkField = (e: any) => {
    setCurrentSelected((prev) => ({ ...prev, link: e.target.value }))
  }

  return (
    <Form
      form={form}
      colon={false}
      initialValues={currentSelected}
      className="flex flex-col w-full pl-[31px] pr-[50px] pt-[36px] pb-[188px]"
    >
      <Row className="flex w-[639px] justify-between">
        <span className="font-bold text-base h-[38px] leading-6 flex items-center">
          {t('building.inputURLAnnotation')}
        </span>
        <Form.Item
          name="link"
          rules={[
            {
              validator: Validators.linkAnnotationValidator(t)
            }
          ]}
        >
          <Input
            placeholder="https://"
            className="h-[38px] rounded-[6px] py-[9px] px-[16px] text-[14px]"
            style={{ width: 516 }}
            onChange={handleChangeLinkField}
          />
        </Form.Item>
      </Row>
      <Row className="flex w-[639px] justify-between">
        <span className="font-bold text-base h-[38px] leading-6 flex items-center">
          {t('building.inputNameAnnotation')}
        </span>
        <Form.Item
          name="name"
          rules={[
            {
              validator: Validators.keywordsAndLengthCustomValidator(
                t,
                500,
                undefined,
                true
              )
            }
          ]}
        >
          <Input
            className="rounded-[6px] py-[9px] px-[16px] text-[14px] h-[38px]"
            style={{ width: 516 }}
            placeholder={t('common.floor.placeHolder')}
            onChange={handleChangeNameField}
          />
        </Form.Item>
      </Row>
    </Form>
  )
}
