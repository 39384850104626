import { useMemo } from 'react'
import { Select, Typography } from 'antd'
import useDirectory from 'hooks/useDirectory'
import { AnnotationType } from 'types/building'

import DropdownIconCustom from 'components/icons/DropdownIconCustom'

type Props = {
  selectedChildrenFolder: any
  selectedFolder: AnnotationType
  changeSelectedFolder: (value: any) => void
  t: any
}

export default function AnnotationTypeSelector({
  selectedChildrenFolder,
  selectedFolder,
  changeSelectedFolder,
  t
}: Props) {
  const { getAnnotationList } = useDirectory()

  const annotationList = useMemo(() => getAnnotationList(), [getAnnotationList])

  if (selectedChildrenFolder && selectedFolder !== AnnotationType.MEMO)
    return null

  return (
    <div className="flex gap-5 items-center h-10 mb-[10px]">
      <Typography className="font-bold text-[14px] leading-[14px] text-[#021120]">
        {t('building.annotationType')}
      </Typography>
      <Select
        value={selectedFolder}
        onSelect={changeSelectedFolder}
        options={annotationList}
        suffixIcon={<DropdownIconCustom colorIcon="#000000" />}
        style={{
          width: 240,
          fontSize: 14,
          color: '#021120'
        }}
      />
    </div>
  )
}
