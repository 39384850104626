import { OrderBy, Pagination } from 'types'
import { AnnotationType, DropdownMenuKey } from 'types/building'

import MenuBuildingIcon from 'components/icons/MenuBuildingIcon'
import MenuOrganizationIcon from 'components/icons/MenuOrganizationIcon'
import MenuUserIcon from 'components/icons/MenuUserIcon'

class Const {
  static get UNAUTHORIZED_ERROR(): string {
    return 'UNAUTHORIZED'
  }

  static get ROLES(): { [key: string]: string[] } {
    return {
      SA: ['SA', 'BA', 'UA', 'USER'],
      BA: ['UA', 'USER'],
      UA: ['UA', 'USER']
    }
  }

  static get PERMISSIONS(): { [key: string]: { [key: string]: string[] } } {
    return {
      organization: {
        create: ['SA'],
        update: ['SA', 'BA', 'UA'],
        delete: ['SA']
      }
    }
  }

  static get DEFAULT_PAGINATION(): Pagination {
    return {
      current: 1,
      defaultPageSize: 10,
      defaultOrderBy: OrderBy.DESC
    }
  }

  static get SIDEBAR_MENU() {
    return [
      {
        key: '/buildings',
        icon: MenuBuildingIcon,
        label: 'common.sidebar.building',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: '/organizations',
        icon: MenuOrganizationIcon,
        label: 'common.sidebar.organization',
        role: ['SA', 'BA', 'UA']
      },
      {
        key: '/users',
        icon: MenuUserIcon,
        label: 'common.sidebar.user',
        role: ['SA', 'BA', 'UA']
      }
    ]
  }

  static get SIDEBAR_DETAIL_BUILDING_MENU() {
    return [
      {
        key: DropdownMenuKey.HANDLE_INFORMATION,
        label: 'common.buildingMenu.information',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: DropdownMenuKey.HANDLE_EDIT_INFORMATION,
        label: 'common.buildingMenu.editInformation',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: DropdownMenuKey.HANDLE_EDIT_STATUS,
        label: 'common.buildingMenu.editStatus',
        role: ['SA', 'BA']
      },
      {
        key: DropdownMenuKey.HANDLE_SHARE_BUILDING,
        label: 'common.buildingMenu.editShare',
        role: ['SA', 'BA', 'UA']
      },
      {
        key: DropdownMenuKey.HANDLE_SETTING_DEFAULT_FLOOR,
        label: 'common.buildingMenu.settingDefaultFloor',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: DropdownMenuKey.HANDLE_EXPORT_IMAGE,
        label: 'common.buildingMenu.exportImage',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: DropdownMenuKey.HANDLE_DELETE_BUILDING,
        label: 'common.buildingMenu.delete',
        role: ['SA', 'BA']
      }
    ]
  }

  static get SIDEBAR_DETAIL_FLOOR_MENU() {
    return [
      {
        key: 'handleEditName',
        label: 'common.floorMenu.handleEditName',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: 'handleUploadMap',
        label: 'common.floorMenu.handleUploadMap',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: 'handleDefaultImage',
        label: 'common.floorMenu.handleDefaultImage',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: 'handleDelete',
        label: 'common.floorMenu.handleDelete',
        role: ['SA', 'BA', 'UA', 'USER']
      }
    ]
  }

  static get SIDEBAR_DETAIL_AREA_MENU() {
    return [
      {
        key: 'name',
        label: 'common.areaMenu.handleEditName',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: 'delete',
        label: 'common.areaMenu.handleDelete',
        role: ['SA', 'BA', 'UA', 'USER']
      }
    ]
  }

  static get PREFECTURES() {
    return [
      {
        prefCode: 1,
        prefName: '北海道'
      },
      {
        prefCode: 2,
        prefName: '青森県'
      },
      {
        prefCode: 3,
        prefName: '岩手県'
      },
      {
        prefCode: 4,
        prefName: '宮城県'
      },
      {
        prefCode: 5,
        prefName: '秋田県'
      },
      {
        prefCode: 6,
        prefName: '山形県'
      },
      {
        prefCode: 7,
        prefName: '福島県'
      },
      {
        prefCode: 8,
        prefName: '茨城県'
      },
      {
        prefCode: 9,
        prefName: '栃木県'
      },
      {
        prefCode: 10,
        prefName: '群馬県'
      },
      {
        prefCode: 11,
        prefName: '埼玉県'
      },
      {
        prefCode: 12,
        prefName: '千葉県'
      },
      {
        prefCode: 13,
        prefName: '東京都'
      },
      {
        prefCode: 14,
        prefName: '神奈川県'
      },
      {
        prefCode: 15,
        prefName: '新潟県'
      },
      {
        prefCode: 16,
        prefName: '富山県'
      },
      {
        prefCode: 17,
        prefName: '石川県'
      },
      {
        prefCode: 18,
        prefName: '福井県'
      },
      {
        prefCode: 19,
        prefName: '山梨県'
      },
      {
        prefCode: 20,
        prefName: '長野県'
      },
      {
        prefCode: 21,
        prefName: '岐阜県'
      },
      {
        prefCode: 22,
        prefName: '静岡県'
      },
      {
        prefCode: 23,
        prefName: '愛知県'
      },
      {
        prefCode: 24,
        prefName: '三重県'
      },
      {
        prefCode: 25,
        prefName: '滋賀県'
      },
      {
        prefCode: 26,
        prefName: '京都府'
      },
      {
        prefCode: 27,
        prefName: '大阪府'
      },
      {
        prefCode: 28,
        prefName: '兵庫県'
      },
      {
        prefCode: 29,
        prefName: '奈良県'
      },
      {
        prefCode: 30,
        prefName: '和歌山県'
      },
      {
        prefCode: 31,
        prefName: '鳥取県'
      },
      {
        prefCode: 32,
        prefName: '島根県'
      },
      {
        prefCode: 33,
        prefName: '岡山県'
      },
      {
        prefCode: 34,
        prefName: '広島県'
      },
      {
        prefCode: 35,
        prefName: '山口県'
      },
      {
        prefCode: 36,
        prefName: '徳島県'
      },
      {
        prefCode: 37,
        prefName: '香川県'
      },
      {
        prefCode: 38,
        prefName: '愛媛県'
      },
      {
        prefCode: 39,
        prefName: '高知県'
      },
      {
        prefCode: 40,
        prefName: '福岡県'
      },
      {
        prefCode: 41,
        prefName: '佐賀県'
      },
      {
        prefCode: 42,
        prefName: '長崎県'
      },
      {
        prefCode: 43,
        prefName: '熊本県'
      },
      {
        prefCode: 44,
        prefName: '大分県'
      },
      {
        prefCode: 45,
        prefName: '宮崎県'
      },
      {
        prefCode: 46,
        prefName: '鹿児島県'
      },
      {
        prefCode: 47,
        prefName: '沖縄県'
      }
    ]
  }

  static get STATUS_BUILDING() {
    return [20, 30, 40, 50]
  }

  static get SA_ROLES_LIST() {
    return [
      {
        label: 'user.roles.systemAdmin',
        value: 'SA'
      },
      {
        label: 'user.roles.branchAdmin',
        value: 'BA'
      },
      {
        label: 'user.roles.user',
        value: 'USER'
      }
    ]
  }

  static get BA_ROLES_LIST() {
    return [
      {
        label: 'user.roles.branchAdmin',
        value: 'BA'
      },
      {
        label: 'user.roles.user',
        value: 'USER'
      }
    ]
  }

  static get UA_ROLES_LIST() {
    return [
      {
        label: 'user.roles.userAdmin',
        value: 'UA'
      },
      {
        label: 'user.roles.user',
        value: 'USER'
      }
    ]
  }

  static get USER_ROLES_LIST() {
    return [
      {
        label: 'user.roles.user',
        value: 'USER'
      }
    ]
  }

  static get DELETE_USER_ERROR() {
    return {
      'ua:role_deletion_error': 'common.validationError.ua_role_deletion',
      'ba:role_deletion_error': 'common.validationError.ba_role_deletion',
      'sa:role_deletion_error': 'common.validationError.sa_role_deletion'
    }
  }

  static get UPDATE_USER_ERROR() {
    return {
      update_error: 'common.validationError.update_error'
    }
  }

  static get OTHER_ORG_DEFAULT() {
    return {
      id: 0,
      name: 'その他',
      domain: ''
    }
  }

  static get KANJI_ONLY(): RegExp {
    return /^[一-龥]+$/
  }

  static get DETAIL_INFORMATION() {
    return [
      {
        key: 'property',
        label: 'building.propertyManagement',
        children: [
          {
            key: 'name',
            label: 'building.buildingNameForm'
          },
          {
            key: 'code',
            label: 'building.code'
          },
          {
            key: 'architectName',
            label: 'building.constructionName'
          },
          {
            key: 'branch?.name',
            label: 'building.branchNameInfo'
          },
          {
            key: 'branch?.serviceStoreName',
            label: 'building.serviceName'
          },
          {
            key: 'address',
            label: 'building.address'
          },
          {
            key: 'createdDate',
            label: 'building.createdDate',
            type: 'date'
          },
          {
            key: 'updateTime',
            label: 'building.updateTime',
            type: 'date'
          }
        ]
      }
    ]
  }

  static get TORISETSU_BUILDING_FOLDER() {
    return [
      {
        id: 10,
        categoryId: 10,
        label: '建築',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 11,
            categoryId: 11,
            label: '取扱説明書',
            isFileHolder: true,
            children: null
          },
          {
            id: 12,
            categoryId: 12,
            label: '製作図・機器完成図',
            isFileHolder: true,
            children: null
          },
          {
            id: 13,
            categoryId: 13,
            label: '申送り',
            isFileHolder: true,
            children: null
          },
          {
            id: 14,
            categoryId: 14,
            label: '竣工図',
            isFileHolder: true,
            children: null
          }
        ]
      },
      {
        id: 20,
        categoryId: 20,
        label: '空調衛生',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 21,
            categoryId: 21,
            label: '取扱説明書',
            isFileHolder: true,
            children: null
          },
          {
            id: 22,
            categoryId: 22,
            label: '製作図・機器完成図',
            isFileHolder: true,
            children: null
          },
          {
            id: 23,
            categoryId: 23,
            label: '申送り',
            isFileHolder: true,
            children: null
          },
          {
            id: 24,
            categoryId: 24,
            label: '竣工図',
            isFileHolder: true,
            children: null
          }
        ]
      },
      {
        id: 30,
        categoryId: 30,
        label: '電気',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 31,
            categoryId: 31,
            label: '取扱説明書',
            isFileHolder: true,
            children: null
          },
          {
            id: 32,
            categoryId: 32,
            label: '製作図・機器完成図',
            isFileHolder: true,
            children: null
          },
          {
            id: 33,
            categoryId: 33,
            label: '申送り',
            isFileHolder: true,
            children: null
          },
          {
            id: 34,
            categoryId: 34,
            label: '竣工図',
            isFileHolder: true,
            children: null
          }
        ]
      },
      {
        id: 100,
        categoryId: 100,
        label: '機材',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 101,
            categoryId: 101,
            label: '取扱説明書',
            isFileHolder: true,
            children: null
          },
          {
            id: 102,
            categoryId: 102,
            label: '製作図・機器完成図',
            isFileHolder: true,
            children: null
          },
          {
            id: 103,
            categoryId: 103,
            label: '申送り',
            isFileHolder: true,
            children: null
          },
          {
            id: 104,
            categoryId: 104,
            label: '竣工図',
            isFileHolder: true,
            children: null
          }
        ]
      },
      {
        id: 41,
        categoryId: 41,
        label: '関連資料',
        isFileHolder: true,
        isCustomFolder: false,
        children: null
      }
    ]
  }

  static get TOUR_BUILDING_FOLDER() {
    return [
      {
        id: 10,
        categoryId: 10,
        label: '建築',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 11,
            categoryId: 11,
            label: '取扱説明書',
            isFileHolder: true,
            children: null
          },
          {
            id: 12,
            categoryId: 12,
            label: '製作図・機器完成図',
            isFileHolder: true,
            children: null
          },
          {
            id: 13,
            categoryId: 13,
            label: '申送り',
            isFileHolder: true,
            children: null
          },
          {
            id: 14,
            categoryId: 14,
            label: '竣工図',
            isFileHolder: true,
            children: null
          }
        ]
      },
      {
        id: 20,
        categoryId: 20,
        label: '空調衛生',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 21,
            categoryId: 21,
            label: '取扱説明書',
            isFileHolder: true,
            children: null
          },
          {
            id: 22,
            categoryId: 22,
            label: '製作図・機器完成図',
            isFileHolder: true,
            children: null
          },
          {
            id: 23,
            categoryId: 23,
            label: '申送り',
            isFileHolder: true,
            children: null
          },
          {
            id: 24,
            categoryId: 24,
            label: '竣工図',
            isFileHolder: true,
            children: null
          }
        ]
      },
      {
        id: 30,
        categoryId: 30,
        label: '電気',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 31,
            categoryId: 31,
            label: '取扱説明書',
            isFileHolder: true,
            children: null
          },
          {
            id: 32,
            categoryId: 32,
            label: '製作図・機器完成図',
            isFileHolder: true,
            children: null
          },
          {
            id: 33,
            categoryId: 33,
            label: '申送り',
            isFileHolder: true,
            children: null
          },
          {
            id: 34,
            categoryId: 34,
            label: '竣工図',
            isFileHolder: true,
            children: null
          }
        ]
      },
      {
        id: 41,
        categoryId: 41,
        label: '関連資料',
        isFileHolder: true,
        isCustomFolder: false,
        children: null
      }
    ]
  }

  static get BCP_BUILDING_FOLDER() {
    return [
      {
        id: 50,
        categoryId: 50,
        label: '火災',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 51,
            categoryId: 51,
            label: '準備',
            isFileHolder: true,
            children: null
          },
          {
            id: 52,
            categoryId: 52,
            label: '発生',
            isFileHolder: true,
            children: null
          },
          {
            id: 53,
            categoryId: 53,
            label: '復旧',
            isFileHolder: true,
            children: null
          }
        ]
      },
      {
        id: 60,
        categoryId: 60,
        label: '水害',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 61,
            categoryId: 61,
            label: '準備',
            isFileHolder: true,
            children: null
          },
          {
            id: 62,
            categoryId: 62,
            label: '発生',
            isFileHolder: true,
            children: null
          },
          {
            id: 63,
            label: '復旧',
            categoryId: 63,
            isFileHolder: true,
            children: null
          }
        ]
      },
      {
        id: 70,
        categoryId: 70,
        label: '地震',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 71,
            categoryId: 71,
            label: '準備',
            isFileHolder: true,
            children: null
          },
          {
            id: 72,
            categoryId: 72,
            label: '発生',
            isFileHolder: true,
            children: null
          },
          {
            id: 73,
            categoryId: 73,
            label: '復旧',
            isFileHolder: true,
            children: null
          }
        ]
      },
      {
        id: 80,
        categoryId: 80,
        label: '降灰',
        isFileHolder: false,
        isCustomFolder: false,
        children: [
          {
            id: 81,
            categoryId: 81,
            label: '準備',
            isFileHolder: true,
            children: null
          },
          {
            id: 82,
            categoryId: 82,
            label: '発生',
            isFileHolder: true,
            children: null
          },
          {
            id: 83,
            categoryId: 83,
            label: '復旧',
            isFileHolder: true,
            children: null
          }
        ]
      }
    ]
  }

  static get TORISETSU_ANNOTATION_LIST() {
    return [
      {
        value: AnnotationType.ARCHITECTURE,
        label: '建築'
      },
      {
        value: AnnotationType.AIR_CONDITION,
        label: '空調衛生'
      },
      {
        value: AnnotationType.ELECTRICITY,
        label: '電気'
      },
      {
        value: AnnotationType.EQUIPMENT,
        label: '機材'
      },
      {
        value: AnnotationType.MEMO,
        label: 'メモ'
      }
    ]
  }

  static get TOUR_ANNOTATION_LIST() {
    return [
      {
        value: AnnotationType.ARCHITECTURE,
        label: '建築'
      },
      {
        value: AnnotationType.AIR_CONDITION,
        label: '空調衛生'
      },
      {
        value: AnnotationType.ELECTRICITY,
        label: '電気'
      },
      {
        value: AnnotationType.MEMO,
        label: 'メモ'
      }
    ]
  }

  static get BCP_ANNOTATION_LIST() {
    return [
      {
        value: AnnotationType.FIRE,
        label: '火災'
      },
      {
        value: AnnotationType.FLOOD,
        label: '水害'
      },
      {
        value: AnnotationType.EARTHQUAKE,
        label: '地震'
      },
      {
        value: AnnotationType.VOLCANO,
        label: '降灰'
      }
    ]
  }

  static get POINT_DROPDOWN_MENU() {
    return [
      {
        key: 'handleDeleteImageLinkWithPoint',
        label: 'common.pointMenuDropdown.handleDeleteImageLinkWithPoint',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: 'handleChangeArea',
        label: 'common.pointMenuDropdown.handleChangeArea',
        role: ['SA', 'BA', 'UA', 'USER']
      },
      {
        key: 'handleDeletePoint',
        label: 'common.pointMenuDropdown.handleDeletePoint',
        role: ['SA', 'BA', 'UA', 'USER']
      }
    ]
  }

  static get WEEKDAYS() {
    return [
      'translation.weekday.sunday',
      'translation.weekday.monday',
      'translation.weekday.tuesday',
      'translation.weekday.wednesday',
      'translation.weekday.thursday',
      'translation.weekday.friday',
      'translation.weekday.saturday'
    ]
  }

  static get BUILDING_CSV_FILEHEADERS() {
    return [
      'name',
      'organizationName',
      'status',
      'branchName',
      'branchStoreName',
      'createdAt',
      'latestUpdated',
      'id',
      'code',
      'aliasname',
      'zipcode',
      'prefName',
      'cityName',
      'streetName',
      'buildingName',
      'siteArea',
      'buildingArea',
      'totalFloorArea',
      'structure',
      'floorNumber',
      'designerName',
      'architectName',
      'constructionPeriod',
      'isDeleted',
      'fileCount'
    ]
  }

  static get BUILDING_CSV_COLUMNHEADERS() {
    return [
      '物件名',
      '組織名',
      'ステータス',
      '担当支店',
      '営業所',
      '作成日',
      '更新日時',
      'ID',
      '略称コード',
      '工事名称',
      '郵便番号',
      '都道府県',
      '市区町村',
      '番地',
      '建物名・部屋番号',
      '敷地面積',
      '建築面積',
      '延床面積',
      '建築構造',
      '階数',
      '設計',
      '施工',
      '施工期間',
      '削除',
      '資料のファイル数'
    ]
  }

  static get QUILL_MODULES() {
    return {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['link', 'image']
      ],
      resize: {
        locale: {}
      },
      clipboard: {
        matchVisual: false
      }
    }
  }

  static get QUILL_FORMATS() {
    return [
      'bold',
      'italic',
      'underline',
      'strike',
      'align',
      'list',
      'header',
      'link',
      'image'
    ]
  }

  static get BCP_ANNOTATION_TABS() {
    return [
      {
        value: 1,
        label: 'translation.1'
      },
      {
        value: 2,
        label: 'translation.2'
      },
      {
        value: 3,
        label: 'translation.3'
      }
    ]
  }

  static get BCP_ANNOTATION_TAB_CATEGORIES() {
    return [
      {
        value: 'video',
        label: 'translation.video'
      },
      {
        value: 'link',
        label: 'translation.link'
      },
      {
        value: 'memo',
        label: 'translation.memo'
      },
      {
        value: 'pdf',
        label: 'translation.pdf'
      },
      {
        value: 'image',
        label: 'translation.image'
      }
    ]
  }
}

export default Const
