import { AnnotationType } from 'types/building'

import AirConditionIcon from 'components/icons/AirConditionIcon'
import DocumentIcon from 'components/icons/DocumentIcon'
import EarthquakeIcon from 'components/icons/EarthquakeIcon'
import EquipmentIcon from 'components/icons/EquipmentIcon'
import FireIcon from 'components/icons/FireIcon'
import FloodIcon from 'components/icons/FloodIcon'
import HouseIcon from 'components/icons/HouseIcon'
import LightBulbIcon from 'components/icons/LightBulbIcon'
import TraditionIcon from 'components/icons/TraditionIcon'
import VolcanoIcon from 'components/icons/VolcanoIcon'

type Props = {
  type: AnnotationType
  size: 'small' | 'large'
}

export default function FolderIcon({ size, type }: Props) {
  switch (type) {
    case AnnotationType.ELECTRICITY:
      return <LightBulbIcon size={size} />
    case AnnotationType.AIR_CONDITION:
      return <AirConditionIcon size={size} />
    case AnnotationType.ARCHITECTURE:
      return <HouseIcon size={size} />
    case AnnotationType.EQUIPMENT:
      return <EquipmentIcon />
    case AnnotationType.FIRE:
      return <FireIcon />
    case AnnotationType.EARTHQUAKE:
      return <EarthquakeIcon />
    case AnnotationType.FLOOD:
      return <FloodIcon />
    case AnnotationType.VOLCANO:
      return <VolcanoIcon />
    case AnnotationType.DOCUMENT:
      return <DocumentIcon size={size} />
    default:
      return <TraditionIcon />
  }
}
