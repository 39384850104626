import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Radio } from 'antd'
import TableColumn from 'constants/tableColumn'
import useMutation from 'hooks/useMutation'
import { useRecoilValue } from 'recoil'
import { floorDetailState, selectedAreaState } from 'store/buildingStore'
import useSWR from 'swr'
import { FloorDetail, OpenModalDelete } from 'types/building'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import ExtraTable from 'components/organisms/ExtraTable'

type Props = {
  open: OpenModalDelete
  setOpen: Dispatch<SetStateAction<OpenModalDelete>>
}

export default function ModalChangePointArea({ open, setOpen }: Props) {
  const { t } = useTranslation()
  const { id: buildingId, floorId } = useParams()
  const [selectedArea, setSelectedArea] = useState<number>()

  const floorDetail = useRecoilValue(floorDetailState)
  const currentSelectedArea = useRecoilValue(selectedAreaState)

  const { trigger, isMutating } = useMutation(`/v1/points/${open.pointId}`)

  const { mutate: refetchPoints } = useSWR<FloorDetail>(
    {
      url: 'v1/points',
      params: {
        buildingId,
        floorId
      }
    },
    {
      revalidateOnMount: false
    }
  )

  const areas = useMemo(
    () =>
      floorDetail?.areas.map((item) => ({
        ...item,
        checkbox: (
          <div className="flex">
            <Radio value={item.id} />
          </div>
        )
      })) || [],
    [floorDetail?.areas]
  )

  const handleChangeCheckbox = (event: any) => {
    setSelectedArea(event.id)
  }

  const handleCancel = () => {
    setOpen({ isOpen: '', pointId: '', pointOrder: 0 })
  }

  const handleSubmit = () => {
    if (
      !isMutating &&
      buildingId &&
      floorId &&
      selectedArea &&
      selectedArea !== currentSelectedArea
    ) {
      trigger(
        {
          method: 'patch',
          data: {
            building_id: +buildingId,
            floor_id: +floorId,
            area_id: selectedArea
          }
        },
        {
          onSuccess: () => {
            handleCancel()
            refetchPoints()
          }
        }
      )
    }
  }

  return (
    <Modal open width={1074}>
      <Title title={t('building.changePointArea')} bottomMargin="mb-[31px]" />
      <Radio.Group
        className="w-full table-list-org-invite"
        defaultValue={currentSelectedArea}
        value={selectedArea}
      >
        <ExtraTable
          isDetail={false}
          scroll={{ y: 350 }}
          columns={TableColumn.COLUMN_CHANGE_POINT_AREA}
          data={areas}
          actionSelect={handleChangeCheckbox}
        />
      </Radio.Group>
      <div className="flex gap-10 justify-center mt-10">
        <Button sizing="w-[140px] h-[50px]" onClick={handleCancel}>
          {t('building.buttonCancel')}
        </Button>

        <Button
          loading={isMutating}
          onClick={handleSubmit}
          type="primary"
          sizing="w-[140px] h-[50px]"
          disabled={!selectedArea || selectedArea === currentSelectedArea}
        >
          {t('building.buttonSave')}
        </Button>
      </div>
    </Modal>
  )
}
