import { MouseEvent } from 'react'
import { useParams } from 'react-router-dom'
import useMutation from 'hooks/useMutation'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  buildingFilesState,
  listPointState,
  revalidatePointDetailState,
  selectedPoint,
  selectedPointDetail,
  selectedSubImageState
} from 'store/buildingStore'
import { currentOpenMenu } from 'store/displayToggleStore'
import { BuildingFile } from 'types/building'

import LazyThumbnail from 'components/atoms/LazyThumbnail'
import DeleteIcon from 'components/icons/DeleteIcon'

type Props = {
  isSelected: boolean
  subImage: BuildingFile
  handleSelectSubImage: (file: BuildingFile) => void
}

export default function PointSubImage({
  isSelected,
  subImage,
  handleSelectSubImage
}: Props) {
  const { id: buildingId, floorId } = useParams()

  const [, setPointList] = useRecoilState(listPointState)
  const [selectedSubImage, setSelectedSubImage] = useRecoilState(
    selectedSubImageState
  )
  const [, setBuildingFiles] = useRecoilState(buildingFilesState)
  const [, setRevalidatePointDetail] = useRecoilState(
    revalidatePointDetailState
  )
  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const pointDetail = useRecoilValue(selectedPointDetail)
  const { isOpenRightMenu } = useRecoilValue(currentOpenMenu)

  const { trigger } = useMutation(
    `v1/points/${currentSelectedPoint}/sub-images`
  )

  const handleDeleteSubimage = (e: MouseEvent<HTMLDivElement>, id: number) => {
    e.stopPropagation()
    trigger(
      {
        method: 'delete',
        data: null,
        query: [`${id}`],
        params: {
          building_id: +(buildingId || 0),
          floor_id: +(floorId || 0)
        }
      },
      {
        onSuccess: () => {
          const subImages = pointDetail?.subImages?.filter(
            (item) => item.id !== id
          )
          setPointList((prev) =>
            prev.map((item) =>
              item.id === currentSelectedPoint
                ? { ...item, isSubImages: Boolean(subImages?.length) }
                : item
            )
          )
          isOpenRightMenu &&
            setBuildingFiles((item) => ({
              ...item,
              files: item.files.map((image) =>
                image.id === id ? { ...image, pointId: null } : image
              )
            }))
          id === selectedSubImage && setSelectedSubImage(undefined)
          currentSelectedPoint === pointDetail?.id &&
            setRevalidatePointDetail(currentSelectedPoint)
        }
      }
    )
  }

  return (
    <div
      className={`min-h-[36px] p-[10px] flex border-0 border-b border-solid theme-border-secondary relative ${
        isSelected ? 'theme-bg-sub-primary' : 'theme-bg-third'
      }`}
      onClick={() => handleSelectSubImage(subImage)}
    >
      <LazyThumbnail
        url={subImage.thumbnail || subImage.s3Path}
        name={subImage.name}
      />
      <div className="absolute top-3 right-3">
        <div
          className="cursor-pointer w-5 h-5 z-20 flex justify-center items-center"
          onClick={(e) => handleDeleteSubimage(e, subImage.id)}
        >
          <DeleteIcon />
        </div>
      </div>
    </div>
  )
}
