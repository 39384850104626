import ImageResizer from 'react-image-file-resizer'

const Resizer = class Resizer {
  static extractBase64FromString = (inputString: string) => {
    const base64Regex =
      /data:image\/(png|jpeg|jpg|gif|webp);base64,([A-Za-z0-9+/=]+)/g
    const matches = []
    let match = base64Regex.exec(inputString)

    while (match !== null) {
      matches.push({
        mimeType: `image/${match[1]}`,
        base64Data: match[0]
      })
      match = base64Regex.exec(inputString)
    }

    return matches
  }

  static base64ToFile = (contentType: string, base64Data: string) => {
    return new Promise((resolve) => {
      const img = new Image()
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        ctx?.drawImage(img, 0, 0)
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], 'temp-blob', { type: contentType })
            resolve(file)
          }
        }, contentType)
      }
      img.src = base64Data
    })
  }

  static resizeBase64Image = (
    file: any,
    inputType: string,
    width?: number,
    height?: number,
    quality?: number,
    outputType?: 'base64' | 'blob' | 'file'
  ) => {
    return new Promise((resolve) => {
      ImageResizer.imageFileResizer(
        file,
        width || 300,
        height || 300,
        inputType,
        quality || 100,
        0,
        (uri) => {
          resolve(uri)
        },
        outputType || 'base64'
      )
    })
  }

  static replaceQuillImageWithResizedImage = (
    quillInnerHtml: string,
    originalBase64: string[],
    resizedBase64: string[]
  ) => {
    let newQuillInnerHtml = quillInnerHtml
    for (let i = 0; i < originalBase64.length; i += 1) {
      newQuillInnerHtml = newQuillInnerHtml.replace(
        originalBase64[i],
        resizedBase64[i]
      )
    }
    return newQuillInnerHtml
  }

  static ResizedQuillImages = async (quillInnerHtml: string) => {
    const originalBase64List = this.extractBase64FromString(quillInnerHtml)
    const promises = originalBase64List.map(async (item) => {
      const itemFile = await this.base64ToFile(item.mimeType, item.base64Data)
      const resizedBase64: any = await this.resizeBase64Image(
        itemFile,
        item.mimeType
      )
      return new Promise((resolve) => {
        resolve(resizedBase64)
      })
    })
    const results: any[] = await Promise.all(promises)
    return this.replaceQuillImageWithResizedImage(
      quillInnerHtml,
      originalBase64List.map((item) => item.base64Data),
      results
    )
  }
}

export default Resizer
