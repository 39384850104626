import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuill } from 'react-quilljs'
import { useParams } from 'react-router-dom'
import { Form, Input, notification, Select, Typography } from 'antd'
import axios from 'axios'
import Const from 'constants/constant'
import useGetAnnotationColor from 'hooks/useGetAnnotationColor'
import useMutation from 'hooks/useMutation'
import { useRecoilState } from 'recoil'
import {
  selectedPointDetail,
  setAnnotationModeState
} from 'store/buildingStore'
import useSWR from 'swr'
import { AnnotationType, BuildingFile, SetAnnotation } from 'types/building'
import Resizer from 'utils/imageResizer'
import Krpano from 'utils/krpano'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'
import RightArrowIcon from 'components/icons/RightArrowIcon'

import BCPSetAnnotationCategoryDetail from './BCPSetAnnotationCategoryDetail'

export default function BCPModalAddSetAnnotation() {
  const { id } = useParams()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const name = Form.useWatch('name', form)
  const {
    quill,
    quillRef,
    Quill: QuillClass
  } = useQuill({
    theme: 'snow',
    modules: Const.QUILL_MODULES,
    formats: Const.QUILL_FORMATS,
    placeholder: '入力してください'
  })

  const { getAnnotationBgColor } = useGetAnnotationColor()

  const [annotationType, setAnnotationType] = useState<AnnotationType>(
    AnnotationType.FIRE
  )
  const [nextAnnotationType, setNextAnnotationType] = useState<AnnotationType>()
  const [selectedCategory, setSelectedCategory] = useState('')
  const [selectedTabs, setSelectedTabs] = useState<number>(1)
  const [nameParse, setNameParse] = useState<string[]>(['', '', ''])
  const [linkParse, setLinkParse] = useState<string[]>(['', '', ''])
  const [memoParse, setMemoParse] = useState<string[]>(['', '', ''])
  const [currentListFile, setCurrentListFile] = useState<BuildingFile[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const annotationBgColor = useMemo(
    () => getAnnotationBgColor(annotationType),
    [getAnnotationBgColor, annotationType]
  )

  const [{ selectedSetAnnotation, annotationId }, setSelectedMode] =
    useRecoilState(setAnnotationModeState)
  const [pointDetail, setPointDetail] = useRecoilState(selectedPointDetail)

  const { data: setAnnotationData, isValidating: isValidatingDetail } =
    useSWR<SetAnnotation>(
      selectedSetAnnotation
        ? {
            url: `v1/buildings/${id}/set-annotations/${selectedSetAnnotation}`
          }
        : null
    )

  const { data: bcpCustomFolders } = useSWR({
    url: `v1/bcp/buildings/${id}/folders`
  })

  const { trigger: triggerAddSetAnnotation, isMutating: isAddSetAnnotation } =
    useMutation(`v1/buildings/${id}/set-annotations`)
  const { trigger: getPresignedUrl, isMutating: isGettingPresignedUrl } =
    useMutation('v1/annotations/presigned-urls')
  const { trigger: deleteOldContent, isMutating: isDeletingOldContent } =
    useMutation('v1/delete-file')

  const annotationList = useMemo(
    () => [
      ...Const.BCP_ANNOTATION_LIST,
      ...(bcpCustomFolders?.map((item: any) => ({
        value: item.categoryId,
        label: item.name
      })) || [])
    ],
    [bcpCustomFolders]
  )

  const listTabs = useMemo((): { value: number; label: string }[] => {
    const customChildrenFolders = bcpCustomFolders?.find(
      (item: any) => item.categoryId === annotationType
    )
    if (
      Object.values(AnnotationType).includes(annotationType || '') ||
      !customChildrenFolders
    ) {
      return Const.BCP_ANNOTATION_TABS.map((item) => ({
        value: annotationType + item.value,
        label: item.label
      }))
    }
    return (
      customChildrenFolders?.childFolders?.map((item: any) => ({
        value: item.categoryId,
        label: item.name
      })) || []
    )
  }, [annotationType, bcpCustomFolders])

  const selectedTabIndex = useMemo(
    () => listTabs.findIndex((item) => item.value === selectedTabs),
    [listTabs, selectedTabs]
  )

  const listMemos = useMemo(
    () => [
      setAnnotationData?.memo,
      setAnnotationData?.memo_occurence,
      setAnnotationData?.memo_recovery
    ],
    [
      setAnnotationData?.memo,
      setAnnotationData?.memo_occurence,
      setAnnotationData?.memo_recovery
    ]
  )

  const handleChangeType = (value: number) => {
    if (selectedSetAnnotation) {
      setNextAnnotationType(value)
      return
    }
    setAnnotationType(value)
  }

  const getLinkContent = () => {
    if (selectedCategory === 'link') {
      return linkParse.map((item: any, index: any) =>
        index === selectedTabIndex ? form.getFieldValue('link').trim() : item
      )
    }
    return linkParse
  }

  const getNameContent = () => {
    if (selectedCategory === 'link') {
      return nameParse.map((item: any, index: any) =>
        index === selectedTabIndex
          ? form.getFieldValue('linkName').trim()
          : item
      )
    }
    return nameParse
  }

  const getMemoContent = () => {
    if (selectedCategory === 'memo') {
      return memoParse.map((item: any, index: any) =>
        index === selectedTabIndex ? quill?.root.innerHTML : item
      )
    }
    return memoParse
  }

  const handleFillParseValue = () => {
    setNameParse(getNameContent())
    setLinkParse(getLinkContent())
    setMemoParse(getMemoContent())
  }

  const handleCategoryBack = () => {
    handleFillParseValue()
    setSelectedCategory('')
  }

  const handleChangeTab = (tab: number) => {
    handleFillParseValue()
    setSelectedTabs(tab)
  }

  const handleCloseModal = () => {
    if (selectedSetAnnotation) {
      if (annotationId) {
        setSelectedMode({
          selectedSetAnnotation: undefined,
          setAnnotationDetail: undefined,
          annotationId: undefined,
          mode: undefined
        })
        return
      }
      setSelectedMode((prev) => ({
        ...prev,
        setAnnotationDetail: undefined,
        mode: 'listSetAnnotation'
      }))
      return
    }
    setSelectedMode((prev) => ({
      ...prev,
      mode: undefined,
      selectedSetAnnotation: undefined
    }))
  }

  const editSelectedSetAnnotation = () => {
    if (selectedSetAnnotation) {
      const krpano = document.getElementById('embedpano-full') as any
      pointDetail?.annotations.forEach((item) => {
        if (item.set_annotation_id === selectedSetAnnotation) {
          switch (annotationType) {
            case 50:
              krpano?.call(`load_style(${item.id}, 'fire')`)
              break
            case 60:
              krpano?.call(`load_style(${item.id}, 'flood')`)
              break
            case 70:
              krpano?.call(`load_style(${item.id}, 'earthquake')`)
              break
            case 80:
              krpano?.call(`load_style(${item.id}, 'volcano')`)
              break
            default:
              krpano?.call(`load_style(${item.id}, 'tradition')`)
              break
          }
        }
      })
      setPointDetail(
        (prev) =>
          prev && {
            ...prev,
            annotations: prev?.annotations.map((item) =>
              item.set_annotation_id === selectedSetAnnotation
                ? { ...item, type: annotationType }
                : item
            )
          }
      )
    }
  }

  const hanldeUploadMemo = async () => {
    const promises = getMemoContent().map(async (item) => {
      if (item) {
        const isMemoEmpty = Krpano.IsMemoEmpty(quill?.root.innerHTML || '')
        if (isMemoEmpty) return null
        const newQuillInnerHtml = await Resizer.ResizedQuillImages(item)
        const file = new Blob([newQuillInnerHtml], {
          type: 'text/plain'
        })
        if (file.size / (1024 * 1024) > 50) {
          notification.error({
            key: 'memoTooLarge',
            message: t('common.validationError.fileTooLarge')
          })
          return null
        }
        const data = await getPresignedUrl({
          data: {
            extension: 'txt',
            fileName: 'annotation-content'
          }
        })
        const { key = undefined, presignedUrl = undefined } = {
          ...data?.data?.s3PreSignedLinks[0]
        }
        await axios.put(presignedUrl, file)
        return new Promise((resolve) => {
          resolve(key)
        })
      }
      return null
    })
    const results: any[] = await Promise.all(promises)
    return results
  }

  const handleAddSetAnnotation = async () => {
    if (isAddSetAnnotation || isGettingPresignedUrl || isDeletingOldContent)
      return
    setIsLoading(true)
    try {
      if (selectedSetAnnotation) {
        listMemos.forEach(async (item) => {
          if (item) {
            await deleteOldContent({
              data: { key: Krpano.GetMemoKeyFromPresignedUrl(item) }
            })
          }
        })
      }
      const uploadedKeys = await hanldeUploadMemo()
      await triggerAddSetAnnotation({
        method: selectedSetAnnotation ? 'patch' : 'post',
        data: {
          linkName: getNameContent()[0],
          linkNameOccurence: getNameContent()[1],
          linkNameRecovery: getNameContent()[2],
          link: getLinkContent()[0],
          linkOccurence: getLinkContent()[1],
          linkRecovery: getLinkContent()[2],
          memo: uploadedKeys[0],
          memoOccurence: uploadedKeys[1],
          memoRecovery: uploadedKeys[2],
          name,
          type: annotationType,
          files: currentListFile.map((item) => item.id)
        },
        query: selectedSetAnnotation ? [selectedSetAnnotation] : []
      })
      setIsLoading(false)
      notification.success({
        message: t('building.saved')
      })
      editSelectedSetAnnotation()
      handleCloseModal()
    } catch (error) {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleSubmit = () => {
    form
      .validateFields(['name'])
      .then(() => {
        handleAddSetAnnotation()
      })
      .catch(() => {})
  }

  const handleConfirmChangeType = () => {
    if (nextAnnotationType) {
      setAnnotationType(nextAnnotationType)
      setLinkParse(['', '', ''])
      setNameParse(['', '', ''])
      setMemoParse(['', '', ''])
      setCurrentListFile([])
      setNextAnnotationType(undefined)
    }
  }

  const underlinePosition = useMemo(
    () => `left-${selectedTabIndex ? `[${selectedTabIndex * 80}px]` : '0'}`,
    [selectedTabIndex]
  )

  const handleFetchContent = useCallback(async () => {
    const newMemos = listMemos.map(async (item) => {
      if (item) {
        const textContent = await Krpano.FetchMemoContent(item)
        return textContent
      }
      return ''
    })
    const results = await Promise.all(newMemos)
    setMemoParse(results)
  }, [listMemos])

  useEffect(() => {
    handleFetchContent()
  }, [handleFetchContent])

  useEffect(() => {
    if (!isValidatingDetail && selectedSetAnnotation && setAnnotationData) {
      setLinkParse([
        setAnnotationData.link,
        setAnnotationData?.link_occurence || '',
        setAnnotationData?.link_recovery || ''
      ])
      setNameParse([
        setAnnotationData.linkName,
        setAnnotationData?.link_name_occurence || '',
        setAnnotationData?.link_name_recovery || ''
      ])
      setAnnotationType(setAnnotationData.type)
      setCurrentListFile(setAnnotationData?.files || [])
      form.setFieldValue('name', setAnnotationData.name)
      setSelectedMode((prev) => ({
        ...prev,
        setAnnotationDetail: setAnnotationData
      }))
    }
  }, [
    form,
    isValidatingDetail,
    selectedSetAnnotation,
    setAnnotationData,
    setSelectedMode
  ])

  useEffect(() => {
    if (listTabs) {
      setSelectedTabs(listTabs[0].value)
    }
  }, [listTabs])

  return (
    <>
      <Modal open width={840} className="paddingless-modal">
        <div className="px-[60px] py-10">
          <Title title={t('building.addSetAnnotation')} />
          <div className="flex gap-5 items-center">
            <Typography className="font-bold text-[14px] leading-[14px] text-[#021120] w-[90px]">
              {t('building.setAnnotationName')}
            </Typography>
            <Form form={form} colon={false}>
              <Form.Item
                name="name"
                className="mb-0 thin-error-message"
                rules={[
                  {
                    validator: Validators.keywordsAndLengthCustomValidator(
                      t,
                      100,
                      true,
                      true
                    )
                  }
                ]}
              >
                <Input
                  className="w-60 h-[38px]"
                  placeholder="入力してください"
                />
              </Form.Item>
            </Form>
          </div>
          <div className="flex gap-5 items-center mt-[30px]">
            <Typography className="font-bold text-[14px] leading-[14px] text-[#021120] w-[90px]">
              {t('building.annotationType')}
            </Typography>
            <Select
              value={annotationType}
              onSelect={(value) => handleChangeType(value)}
              options={annotationList}
              suffixIcon={<DropdownIconCustom colorIcon="#000000" />}
              style={{
                width: 240,
                fontSize: 14,
                color: '#021120'
              }}
            />
          </div>
          <div
            className={`flex items-center h-[50px] mt-[10px] ${annotationBgColor}`}
          >
            {selectedCategory ? (
              <div
                className="cursor-pointer h-6 ml-5"
                onClick={handleCategoryBack}
              >
                <LeftArrowIcon size={24} />
              </div>
            ) : null}
            <div className="flex relative">
              {listTabs.map((item) => (
                <div
                  key={item.value}
                  className="flex w-20 h-[50px] cursor-pointer"
                  onClick={() => handleChangeTab(item.value)}
                >
                  <div className="text-base text-white m-auto">
                    {t(item.label)}
                  </div>
                </div>
              ))}
              {listTabs.length ? (
                <div
                  className={`absolute ${underlinePosition} bottom-0 transition-all w-20 h-[5px] bg-[#A1C6B4]`}
                />
              ) : null}
            </div>
          </div>
          <div>
            {!selectedCategory ? (
              Const.BCP_ANNOTATION_TAB_CATEGORIES.map((item) => (
                <div
                  key={item.value}
                  className="flex items-center justify-between h-[60px] border-0 border-b border-solid border-[#d9d9d9] cursor-pointer"
                  onClick={() => setSelectedCategory(item.value)}
                >
                  <div className="text-base text-[#021120] font-medium ml-[10px]">
                    {t(item.label)}
                  </div>
                  <div className="mr-5">
                    <RightArrowIcon color="#021120" />
                  </div>
                </div>
              ))
            ) : (
              <BCPSetAnnotationCategoryDetail
                form={form}
                selectedType={annotationType}
                selectedTabIndex={selectedTabIndex}
                selectedTabs={selectedTabs}
                selectedCategory={selectedCategory}
                linkParse={linkParse}
                nameParse={nameParse}
                memoParse={memoParse}
                currentListFile={currentListFile}
                quill={quill}
                quillRef={quillRef}
                QuillClass={QuillClass}
                setCurrentListFile={setCurrentListFile}
              />
            )}
          </div>
          <div className="pt-5 pr-[30px] flex gap-10">
            {selectedSetAnnotation && (
              <Button
                sizing="w-[140px] h-[50px]"
                className="bg-[#B80808]"
                onClick={() =>
                  setSelectedMode((prev) => ({
                    ...prev,
                    mode: 'deleteSetAnnotation'
                  }))
                }
              >
                {t('translation.delete')}
              </Button>
            )}
            <div className="flex gap-10 ml-auto">
              <Button sizing="w-[140px] h-[50px]" onClick={handleCloseModal}>
                {t('translation.cancel')}
              </Button>
              <Button
                sizing="w-[140px] h-[50px]"
                type="primary"
                loading={isLoading}
                onClick={handleSubmit}
              >
                {t('building.buttonSave')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>

      {nextAnnotationType ? (
        <Modal open width={580} rootClassName="shared-permission-modal">
          <div className="flex flex-col gap-10 text-center p-5">
            <Typography className="text-[14px] leading-[21px]">
              {t('building.confirmChangeAnnotation')}
            </Typography>

            <div className="flex gap-5 mt-5">
              <Button
                sizing="w-[140px] h-[50px] mx-auto"
                onClick={() => setNextAnnotationType(undefined)}
              >
                {t('building.buttonCancel')}
              </Button>
              <Button
                sizing="w-[140px] h-[50px] mx-auto"
                onClick={handleConfirmChangeType}
                type="primary"
              >
                {t('building.buttonSave')}
              </Button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  )
}
