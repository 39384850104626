import { AnnotationType } from 'types/building'

export default function useGetAnnotationColor() {
  const getAnnotationBgColor = (annotationType: number) => {
    switch (annotationType) {
      case AnnotationType.ARCHITECTURE:
        return 'bg-[#454DFF]'
      case AnnotationType.ELECTRICITY:
        return 'bg-[#F1AE00]'
      case AnnotationType.AIR_CONDITION:
        return 'bg-[#02EADC]'
      case AnnotationType.EQUIPMENT:
        return 'bg-[#A074F8]'
      case AnnotationType.FIRE:
        return 'bg-[#FF4242]'
      case AnnotationType.FLOOD:
        return 'bg-[#015CB9]'
      case AnnotationType.EARTHQUAKE:
        return 'bg-[#EDE054]'
      case AnnotationType.VOLCANO:
        return 'bg-[#64A45F]'
      default:
        return 'bg-[#4B4B4B]'
    }
  }

  return { getAnnotationBgColor }
}
