import AppDownload from 'pages/app-download'
import NewsFeedPage from 'pages/news-feed'
import NotFound from 'pages/not-found'
import NotificationDetailPage from 'pages/notification-detail'
import Policies from 'pages/policies'
import { RouteObject } from 'types'

import DefaultLayout from 'components/templates/DefaultLayout'
import EmptyLayout from 'components/templates/EmptyLayout'

const publicRoutes: RouteObject = {
  name: 'public-routes',
  path: '',
  layout: DefaultLayout,
  children: [
    {
      name: 'app-download',
      path: '/app',
      element: <AppDownload />
    },
    {
      name: 'policies',
      path: '/policies',
      element: <Policies />
    },
    {
      name: 'not-found',
      path: '/*',
      element: <NotFound />
    },
    {
      name: 'notification-detail',
      path: 'notifications/:id',
      layout: EmptyLayout,
      element: <NotificationDetailPage />
    },
    {
      name: 'news',
      path: 'news',
      layout: EmptyLayout,
      element: <NewsFeedPage />
    }
  ]
}

export default publicRoutes
