import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown, Typography } from 'antd'
import Const from 'constants/constant'
import useDirectory from 'hooks/useDirectory'
import { CurrentUser } from 'models/User'
import DeleteBuildingModal from 'pages/building/buildingDetail/components/ModalDeleteBuilding'
import ShareBuildingModal from 'pages/building/buildingDetail/components/ModalShareBuilding'
import { BuildingLeftMenu, DropdownMenuKey } from 'types/building'

import MenuIcon from 'components/icons/MenuIcon'

import ModalChangeStatus from './ModalChangeStatus'
import ModalEditBuildingDetail from './ModalEditBuildingDetail'
import ModalSettingDefaultFloor from './ModalSettingDefaultFloor'

type Props = {
  handleSelectOption: (option: BuildingLeftMenu) => void
  profile: CurrentUser | null | undefined
}

export default function DropdownMenu({ handleSelectOption, profile }: Props) {
  const { t } = useTranslation()

  const [selectedMenu, setSelectedMenu] = useState('')

  const { getDirectory } = useDirectory()

  const handleCancel = () => setSelectedMenu('')
  const handleOpenMenu = (menu: string) => {
    switch (menu) {
      case DropdownMenuKey.HANDLE_INFORMATION:
        handleSelectOption(BuildingLeftMenu.INFORMATION)
        break
      case DropdownMenuKey.HANDLE_EXPORT_IMAGE:
        handleSelectOption(BuildingLeftMenu.EXPORT_IMAGES)
        break
      default:
        setSelectedMenu(menu)
        break
    }
  }

  const items = Const.SIDEBAR_DETAIL_BUILDING_MENU.filter(
    (item) => profile?.role && item.role.includes(profile?.role)
  ).map(({ label, key }) => {
    return {
      key,
      label: (
        <Typography className="text-white text-[12px] font-[700]">
          {t(label)}
        </Typography>
      ),
      onClick: () => handleOpenMenu(key)
    }
  })

  return (
    <div className="h-full cursor-pointer">
      <Dropdown
        menu={{ items }}
        placement="bottomRight"
        trigger={['click']}
        overlayClassName={`${getDirectory()}-theme`}
        rootClassName="w-36 dropdown-custom"
      >
        <div className="h-full flex my-auto pr-[10px]">
          <MenuIcon />
        </div>
      </Dropdown>
      {selectedMenu === DropdownMenuKey.HANDLE_EDIT_INFORMATION && (
        <ModalEditBuildingDetail handleCancel={handleCancel} />
      )}
      {selectedMenu === DropdownMenuKey.HANDLE_EDIT_STATUS && (
        <ModalChangeStatus handleCancel={handleCancel} />
      )}
      {selectedMenu === DropdownMenuKey.HANDLE_SHARE_BUILDING && (
        <ShareBuildingModal handleCancel={handleCancel} />
      )}
      {selectedMenu === DropdownMenuKey.HANDLE_DELETE_BUILDING && (
        <DeleteBuildingModal type="building" handleCancel={handleCancel} />
      )}
      {selectedMenu === DropdownMenuKey.HANDLE_SETTING_DEFAULT_FLOOR && (
        <ModalSettingDefaultFloor handleCancel={handleCancel} />
      )}
    </div>
  )
}
