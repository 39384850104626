import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuill } from 'react-quilljs'
import { useSearchParams } from 'react-router-dom'
import { DatePicker, Form, Input, notification, Radio, Typography } from 'antd'
import axios from 'axios'
import Const from 'constants/constant'
import dayjs from 'dayjs'
import useMutation from 'hooks/useMutation'
import QuillResizeImage from 'quill-resize-image'
import useSWR from 'swr'
import { OrderByType } from 'types'
import { NotificationDetail } from 'types/notification'
import Utils from 'utils'
import Resizer from 'utils/imageResizer'
import Validators from 'utils/validators'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'

type Props = {
  handleCloseModal: () => void
}

export default function ModalAddEditNotify({ handleCloseModal }: Props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const publicationStartAt = Form.useWatch('publicationStartAt', form)
  const publicationEndAt = Form.useWatch('publicationEndAt', form)
  const startAndEndDiff =
    publicationStartAt && publicationEndAt
      ? dayjs(publicationStartAt).diff(dayjs(publicationEndAt), 'minute')
      : -1

  const [searchParams] = useSearchParams()
  const page = searchParams.get('page')
  const selectedNotify = searchParams.get('selected_notify')

  const [isSubmiting, setIsSubmiting] = useState(false)
  const [isLoadingContent, setIsLoadingContent] = useState(false)
  const [isConfirmDelete, setIsConfirmDelete] = useState(false)

  const { quill, quillRef, Quill } = useQuill({
    theme: 'snow',
    modules: Const.QUILL_MODULES,
    formats: Const.QUILL_FORMATS,
    placeholder: '入力してください'
  })

  const params = useMemo(() => {
    const convertedQueries = Object.fromEntries(searchParams.entries())
    return {
      ...convertedQueries,
      page: page || 1,
      pageSize: 10,
      sort: convertedQueries?.sort,
      sortOrder: convertedQueries?.sortOrder
        ? convertedQueries.sortOrder
        : OrderByType.descend
    }
  }, [page, searchParams])

  const { mutate: refetchList } = useSWR(
    {
      url: '/v1/notifications',
      params
    },
    {
      revalidateOnMount: false
    }
  )

  // get presigned url
  const { trigger: getPresignedUrl, isMutating: isGettingPresignedUrl } =
    useMutation('v1/notifications/presigned-url')
  // create new notification
  const { trigger: handleNotification, isMutating: isHandlingNotification } =
    useMutation('v1/notifications')
  // delete old s3 file
  const { trigger: deleteOldContent, isMutating: isDeletingOldContent } =
    useMutation('v1/delete-file')

  const { data, isValidating } = useSWR<NotificationDetail>(
    selectedNotify
      ? {
          url: `/v1/notifications/${selectedNotify}`
        }
      : null,
    {
      onError: () => {
        handleCloseModal()
      }
    }
  )

  const disabledStartDate = (currentDate: any) => {
    return (
      currentDate &&
      publicationEndAt &&
      currentDate > dayjs(publicationEndAt).endOf('day')
    )
  }

  const disabledEndDate = (currentDate: any) => {
    return (
      currentDate &&
      publicationStartAt &&
      dayjs(currentDate).add(1, 'day') < dayjs(publicationStartAt).endOf('day')
    )
  }

  const range = (start: number, end: number) => {
    const result = []
    for (let i = start; i < end; i += 1) {
      result.push(i)
    }
    return result
  }

  const disabledDateTimeStart = (currentDate: any) => {
    if (publicationEndAt) {
      const currentDay = dayjs(currentDate).day()
      const currentHour = dayjs(currentDate).hour()
      const day = dayjs(publicationEndAt).day()
      const hour = dayjs(publicationEndAt).hour()
      const minute = dayjs(publicationEndAt).minute()
      return {
        disabledHours: () =>
          currentDay === day ? range(0, 24).splice(hour + 1) : [],
        disabledMinutes: () =>
          currentDay === day && currentHour === hour
            ? range(0, 60).splice(minute)
            : []
      }
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => []
    }
  }

  const disabledDateTimeEnd = (currentDate: any) => {
    if (publicationStartAt) {
      const currentDay = dayjs(currentDate).day()
      const currentHour = dayjs(currentDate).hour()
      const day = dayjs(publicationStartAt).day()
      const hour = dayjs(publicationStartAt).hour()
      const minute = dayjs(publicationStartAt).minute()
      return {
        disabledHours: () =>
          currentDay === day ? range(0, 24).slice(0, hour) : [],
        disabledMinutes: () =>
          currentDay === day && currentHour === hour
            ? range(0, 60).slice(0, minute + 1)
            : []
      }
    }
    return {
      disabledHours: () => [],
      disabledMinutes: () => []
    }
  }

  const handleDeleteNotify = () => {
    if (
      !isGettingPresignedUrl &&
      !isHandlingNotification &&
      !isDeletingOldContent
    ) {
      if (selectedNotify) {
        handleNotification(
          {
            method: 'delete',
            data: null,
            query: selectedNotify ? [+selectedNotify] : undefined
          },
          {
            onSuccess: () => {
              refetchList()
              setIsConfirmDelete(false)
              handleCloseModal()
            }
          }
        )
      }
    }
  }

  const handleCreateOrUpdate = async (values: any) => {
    if (quill) {
      const newQuillInnerHtml = await Resizer.ResizedQuillImages(
        quill?.root.innerHTML || ''
      )
      const file = new Blob([newQuillInnerHtml], {
        type: 'text/plain'
      })
      if (file.size / (1024 * 1024) > 50) {
        notification.error({
          key: 'notifyTooLarge',
          message: t('common.validationError.fileTooLarge')
        })
        return
      }
      await deleteOldContent({
        data: {
          key: data?.s3Path
        }
      })
      const res = await getPresignedUrl({
        data: {
          extension: 'txt',
          fileName: 'notification-content'
        }
      })
      const { key = undefined, presignedUrl = undefined } = {
        ...res?.data?.s3PreSignedLinks[0]
      }
      await axios.put(presignedUrl, file)
      handleNotification(
        {
          data: {
            ...Utils.trimObjectValues(values),
            s3Path: key,
            mimeType: 'txt',
            extension: 'txt',
            fileName: 'notification-content'
          },
          query: selectedNotify ? [+selectedNotify] : undefined
        },
        {
          onSuccess: () => {
            refetchList()
            handleCloseModal()
          }
        }
      )
    }
  }

  const handleSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const values = form.getFieldsValue()
        if (startAndEndDiff >= 0) return
        setIsSubmiting(true)
        if (
          isGettingPresignedUrl ||
          isHandlingNotification ||
          isDeletingOldContent ||
          selectedNotify
        ) {
          return
        }
        deleteOldContent(
          {
            data: {
              key: data?.s3Path
            }
          },
          {
            onSuccess: () => {
              handleCreateOrUpdate(values)
            }
          }
        )
        handleCreateOrUpdate(values)
      })
      .catch(() => {})
  }

  const handleFetchContent = useCallback(async () => {
    try {
      if (data?.s3FileURL && quill) {
        const response = await fetch(data.s3FileURL)
        const blob = await response.blob()
        const reader = new FileReader()
        reader.onload = async () => {
          const text = reader.result as string
          quill.root.innerHTML = text
          setIsLoadingContent(false)
        }
        reader.readAsText(blob)
      }
    } catch (error) {}
  }, [data?.s3FileURL, quill])

  useEffect(() => {
    if (Quill && !quill) {
      Quill.register('modules/resize', QuillResizeImage)
    }
  }, [Quill, quill])

  useEffect(() => {
    if (!isValidating && data) {
      setIsLoadingContent(true)
      handleFetchContent()
    }
  }, [data, handleFetchContent, isValidating])

  useEffect(() => {
    if (startAndEndDiff >= 0) {
      form.setFields([
        {
          name: 'publicationStartAt',
          errors: ['公開終了日時には公開開始日時以降の日付を指定して下さい。']
        }
      ])
    } else {
      form.setFields([
        {
          name: 'publicationStartAt',
          errors: []
        }
      ])
    }
  }, [form, startAndEndDiff])

  useEffect(() => {
    if (!isValidating) {
      if (data) {
        form.setFieldsValue({
          ...data,
          publicationStartAt:
            data.publicationStartAt && dayjs(data.publicationStartAt),
          publicationEndAt:
            data.publicationEndAt && dayjs(data.publicationEndAt)
        })
      } else {
        form.setFieldValue('publicationStatus', 'PUBLIC')
      }
    }
  }, [data, form, isValidating])

  return (
    <>
      <Modal open width={690} className="paddingless-modal">
        <Spinner spinning={isValidating || isSubmiting}>
          <div className="pt-10 pb-[15px]">
            <Title
              title={t(
                `translation.${selectedNotify ? 'editNotify' : 'createNotify'}`
              )}
            />

            <Form
              form={form}
              labelCol={{ span: 6 }}
              labelAlign="left"
              wrapperCol={{ span: 18 }}
              colon={false}
            >
              <div className="h-[500px] overflow-y-auto mx-[15px] mb-2">
                <div className="w-[470px] mx-auto">
                  <Form.Item
                    label={
                      <Typography className="text-[14px] leading-[21px] text-[#021120]">
                        {t('translation.title')}
                      </Typography>
                    }
                    name="title"
                    className="thin-error-message"
                    rules={[
                      {
                        validator: Validators.keywordsAndLengthCustomValidator(
                          t,
                          200,
                          true,
                          true
                        )
                      }
                    ]}
                  >
                    <Input
                      placeholder="入力してください"
                      className="h-[38px]"
                    />
                  </Form.Item>

                  <Typography className="text-[14px] leading-[21px] text-[#021120] font-medium mb-5">
                    内容
                  </Typography>
                  <Spinner spinning={isLoadingContent}>
                    <div style={{ height: 300, marginBottom: 80 }}>
                      <div
                        ref={quillRef}
                        className="quill-custom-editor overflow-hidden [&>#editor-resizer>.toolbar]:!hidden [&>.ql-editor]:pb-10"
                      />
                    </div>
                  </Spinner>
                  <Form.Item
                    label={
                      <Typography className="text-[14px] leading-[21px] text-[#021120]">
                        {t('translation.notifyStatus')}
                      </Typography>
                    }
                    name="publicationStatus"
                    className="thin-error-message"
                  >
                    <Radio.Group>
                      <Radio value="PUBLIC">{t('translation.public')}</Radio>
                      <Radio value="PRIVATE">{t('translation.private')}</Radio>
                    </Radio.Group>
                  </Form.Item>
                  <Form.Item
                    label={
                      <Typography className="text-[14px] leading-[21px] text-[#021120]">
                        {t('translation.publicationStartAt')}
                      </Typography>
                    }
                    name="publicationStartAt"
                  >
                    <DatePicker
                      placeholder=""
                      className="h-[38px] text-sm w-60"
                      showTime
                      format="YYYY-MM-DD HH:mm"
                      disabledDate={disabledStartDate}
                      disabledTime={disabledDateTimeStart}
                    />
                  </Form.Item>
                  <Form.Item
                    label={
                      <Typography className="text-[14px] leading-[21px] text-[#021120]">
                        {t('translation.publicationEndAt')}
                      </Typography>
                    }
                    name="publicationEndAt"
                  >
                    <DatePicker
                      placeholder=""
                      className="h-[38px] text-sm w-60"
                      showTime
                      format="YYYY-MM-DD HH:mm"
                      disabledDate={disabledEndDate}
                      disabledTime={disabledDateTimeEnd}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="border-0 border-t border-solid border-[#EEEFF2] pt-5">
                <div
                  className={`w-[470px] mx-auto flex ${
                    selectedNotify ? 'justify-between' : 'justify-center gap-10'
                  }`}
                >
                  {selectedNotify && (
                    <Button
                      className="bg-[#B80808]"
                      onClick={() => setIsConfirmDelete(true)}
                    >
                      {t('translation.delete')}
                    </Button>
                  )}
                  <Button onClick={handleCloseModal}>
                    {t('translation.cancel')}
                  </Button>
                  <Button type="primary" onClick={handleSubmit}>
                    {t('common.confirm')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </Spinner>
      </Modal>
      <Modal open={isConfirmDelete} className="paddingless-modal">
        <div className="px-[60px] py-[50px] flex flex-col items-center">
          <Title title={t('translation.deleteNotification')} />
          <div className="mt-2">{t('translation.deleteFolderConfirm')}</div>
          <div className="flex justify-center gap-10 mt-10">
            <Button
              sizing="w-[140px] h-[50px]"
              onClick={() => setIsConfirmDelete(false)}
            >
              {t('translation.cancel')}
            </Button>
            <Button
              sizing="w-[140px] h-[50px]"
              className="bg-[#B80808]"
              onClick={handleDeleteNotify}
              loading={isHandlingNotification}
            >
              {t('translation.delete')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
