import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuill } from 'react-quilljs'
import { useParams } from 'react-router-dom'
import { Form, notification, Select, Typography } from 'antd'
import axios from 'axios'
import Const from 'constants/constant'
import useDirectory from 'hooks/useDirectory'
import useGetAnnotationColor from 'hooks/useGetAnnotationColor'
import useMutation from 'hooks/useMutation'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  selectedPoint,
  selectedPointDetail,
  selectedSubImageState
} from 'store/buildingStore'
import { currentAnnotation } from 'store/krpanoStore'
import useSWR from 'swr'
import { AnnotationType } from 'types/building'
import Utils from 'utils'
import Resizer from 'utils/imageResizer'
import Krpano from 'utils/krpano'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Title from 'components/atoms/Title'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'
import RightArrowIcon from 'components/icons/RightArrowIcon'

import BCPAnnotationCategoryDetail from './BCPAnnotationCategoryDetail'
import BCPConfirmChangeAnnotation from './BCPConfirmChangeAnnotation'
import BCPDeleteAnnotationModal from './BCPDeleteAnnotationModal'

export default function BCPAnnotationModal() {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const [form] = Form.useForm()
  const { navigate } = useDirectory()
  const { getAnnotationBgColor } = useGetAnnotationColor()
  const {
    quill,
    quillRef,
    Quill: QuillClass
  } = useQuill({
    theme: 'snow',
    modules: Const.QUILL_MODULES,
    formats: Const.QUILL_FORMATS,
    placeholder: '入力してください'
  })

  const [selectedType, setSelectedType] = useState<AnnotationType>(
    AnnotationType.FIRE
  )
  const [selectedTabs, setSelectedTabs] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState('')
  const [isChangeType, setIsChangeType] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const [nameParse, setNameParse] = useState<string[]>(['', '', ''])
  const [linkParse, setLinkParse] = useState<string[]>(['', '', ''])
  const [memoParse, setMemoParse] = useState<string[]>(['', '', ''])
  const [isLoading, setIsLoading] = useState(false)

  const selectedSubImage = useRecoilValue(selectedSubImageState)
  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const [currentSelected, setCurrentSelected] =
    useRecoilState(currentAnnotation)
  const [pointDetail, setPointDetail] = useRecoilState(selectedPointDetail)

  const annotationBgColor = useMemo(
    () => getAnnotationBgColor(selectedType),
    [getAnnotationBgColor, selectedType]
  )
  const listMemos = useMemo(
    () => [
      currentSelected?.memo,
      currentSelected?.memo_occurence,
      currentSelected?.memo_recovery
    ],
    [
      currentSelected?.memo,
      currentSelected?.memo_occurence,
      currentSelected?.memo_recovery
    ]
  )

  const isDataExistsAnnotation =
    !currentSelected?.files?.length &&
    !currentSelected?.link &&
    !currentSelected?.name

  const { trigger, isMutating } = useMutation(`v1/annotations`)
  const { trigger: getPresignedUrl, isMutating: isGettingPresignedUrl } =
    useMutation('v1/annotations/presigned-urls')
  const { trigger: deleteOldContent, isMutating: isDeletingOldContent } =
    useMutation('v1/delete-file')

  const { data: bcpCustomFolders } = useSWR({
    url: `v1/bcp/buildings/${id}/folders`
  })

  const annotationList = useMemo(
    () => [
      ...Const.BCP_ANNOTATION_LIST,
      ...(bcpCustomFolders || []).map((item: any) => ({
        value: item.categoryId,
        label: item.name
      }))
    ],
    [bcpCustomFolders]
  )

  const listTabs = useMemo((): { value: number; label: string }[] => {
    if (Object.values(AnnotationType).includes(selectedType || '')) {
      return Const.BCP_ANNOTATION_TABS.map((item) => ({
        value: selectedType + item.value,
        label: item.label
      }))
    }
    return (
      bcpCustomFolders
        ?.find((item: any) => item.categoryId === selectedType)
        ?.childFolders?.map((item: any) => ({
          value: item.categoryId,
          label: item.name
        })) || []
    )
  }, [bcpCustomFolders, selectedType])

  const selectedTabIndex = useMemo(
    () => listTabs.findIndex((item) => item.value === selectedTabs),
    [listTabs, selectedTabs]
  )

  const changeSelectedType = useCallback(
    (value: any) => {
      if (value !== selectedType) {
        setCurrentSelected((prev) => ({
          ...prev,
          type: value
        }))
        if (!isDataExistsAnnotation) {
          setIsChangeType(true)
        } else {
          setSelectedType(value)
          setSelectedTabs(listTabs[0].value)
        }
      }
    },
    [isDataExistsAnnotation, listTabs, selectedType, setCurrentSelected]
  )

  const handleCloseModal = () => {
    setCurrentSelected(undefined)
    Krpano.RemoveTempAnnotation()
    notification.destroy('placingAnnotation')
  }

  const hotspotLoadStyle = (krpano: any, data: any) => {
    krpano.call(`remove_annotation(${data.id})`)
    Krpano.AddAnnotation(krpano, [data])
  }

  const createNewAnnotation = (krpano: any, data: any) => {
    krpano.call('hide_temp_hotspot()')
    Krpano.AddAnnotation(krpano, [data])
  }

  const getLinkContent = () => {
    if (selectedCategory === 'link') {
      return linkParse.map((item: any, index: any) =>
        index === selectedTabIndex ? form.getFieldValue('link').trim() : item
      )
    }
    return linkParse
  }

  const getNameContent = () => {
    if (selectedCategory === 'link') {
      return nameParse.map((item: any, index: any) =>
        index === selectedTabIndex ? form.getFieldValue('name').trim() : item
      )
    }
    return nameParse
  }

  const getMemoContent = () => {
    if (selectedCategory === 'memo') {
      return memoParse.map((item: any, index: any) =>
        index === selectedTabIndex ? quill?.root.innerHTML : item
      )
    }
    return memoParse
  }

  const handleFillParseValue = () => {
    setNameParse(getNameContent())
    setLinkParse(getLinkContent())
    setMemoParse(getMemoContent())
  }

  const handleCategoryBack = () => {
    handleFillParseValue()
    setSelectedCategory('')
  }

  const handleChangeTab = (tab: number) => {
    handleFillParseValue()
    setSelectedTabs(tab)
  }

  const hanldeUploadMemo = async () => {
    const promises = getMemoContent().map(async (item) => {
      if (item) {
        const isMemoEmpty = Krpano.IsMemoEmpty(quill?.root.innerHTML || '')
        if (isMemoEmpty) return null
        const newQuillInnerHtml = await Resizer.ResizedQuillImages(item)
        const file = new Blob([newQuillInnerHtml], {
          type: 'text/plain'
        })
        if (file.size / (1024 * 1024) > 50) {
          notification.error({
            key: 'memoTooLarge',
            message: t('common.validationError.fileTooLarge')
          })
          return null
        }
        const data = await getPresignedUrl({
          data: {
            extension: 'txt',
            fileName: 'annotation-content'
          }
        })
        const { key = undefined, presignedUrl = undefined } = {
          ...data?.data?.s3PreSignedLinks[0]
        }
        await axios.put(presignedUrl, file)
        return new Promise((resolve) => {
          resolve(key)
        })
      }
      return null
    })
    const results: any[] = await Promise.all(promises)
    return results
  }

  const handleDeleteOldContent = useCallback(() => {
    if (currentSelected?.id) {
      listMemos.forEach(async (item) => {
        if (item) {
          await deleteOldContent({
            data: { key: Krpano.GetMemoKeyFromPresignedUrl(item) }
          })
        }
      })
    }
  }, [currentSelected?.id, listMemos, deleteOldContent])

  const getImageID = () => {
    if (!pointDetail?.subImages?.length) {
      return null
    }
    const isViewingSubImage = pointDetail?.subImages?.find(
      (item) => item.id === selectedSubImage
    )
    if (isViewingSubImage) {
      return selectedSubImage
    }
    return pointDetail.image360?.id
  }

  const handleAddOrEditAnnotation = async () => {
    if (isMutating || isGettingPresignedUrl || isDeletingOldContent) return
    const krpano = document.getElementById('embedpano-full') as any
    setIsLoading(true)
    try {
      handleDeleteOldContent()
      const uploadedKeys = await hanldeUploadMemo()
      const convertedValue = {
        ...currentSelected,
        name: getNameContent()[0],
        name_occurence: getNameContent()[1],
        name_recovery: getNameContent()[2],
        link: getLinkContent()[0],
        link_occurence: getLinkContent()[1],
        link_recovery: getLinkContent()[2],
        memo: uploadedKeys[0],
        memo_occurence: uploadedKeys[1],
        memo_recovery: uploadedKeys[2],
        type: selectedType,
        files:
          currentSelected?.files &&
          currentSelected?.files.map((item) => item.id),
        imageID: getImageID()
      }
      const { data } = await trigger({
        method: 'put',
        data: {
          building_id: Number(id),
          floor_id: Number(floorId),
          point_id: currentSelectedPoint,
          annotation: convertedValue
        }
      })
      setIsLoading(false)
      notification.success({
        message: t('building.saved')
      })
      if (!convertedValue.id) {
        setPointDetail(
          (prev) =>
            prev && {
              ...prev,
              annotations: [...(prev?.annotations || []), data]
            }
        )
        createNewAnnotation(krpano, data)
      } else {
        setPointDetail(
          (prev) =>
            prev && {
              ...prev,
              annotations: prev.annotations.map((item) =>
                item.id === data.id ? data : item
              )
            }
        )
        hotspotLoadStyle(krpano, data)
      }
      handleCloseModal()
    } catch (error) {
      setIsLoading(false)
      krpano.call('hide_temp_hotspot()')
      notification.destroy('placingAnnotation')
      Utils.handleErrorNavigate(error, navigate)
    }
  }

  const handleFetchContent = useCallback(async () => {
    const newMemos = listMemos.map(async (item) => {
      if (item) {
        const textContent = await Krpano.FetchMemoContent(item)
        return textContent
      }
      return ''
    })
    const results = await Promise.all(newMemos)
    setMemoParse(results)
  }, [listMemos])

  useEffect(() => {
    handleFetchContent()
  }, [handleFetchContent])

  useEffect(() => {
    if (currentSelected?.type) {
      setSelectedType(currentSelected.type)
    }
  }, [currentSelected?.type])

  useEffect(() => {
    if (
      currentSelected?.name ||
      currentSelected?.name_occurence ||
      currentSelected?.name_recovery
    ) {
      setNameParse([
        currentSelected?.name || '',
        currentSelected?.name_occurence || '',
        currentSelected?.name_recovery || ''
      ])
    }
  }, [
    currentSelected?.name,
    currentSelected?.name_occurence,
    currentSelected?.name_recovery
  ])

  useEffect(() => {
    if (
      currentSelected?.link ||
      currentSelected?.link_occurence ||
      currentSelected?.link_recovery
    ) {
      setLinkParse([
        currentSelected?.link || '',
        currentSelected?.link_occurence || '',
        currentSelected?.link_recovery || ''
      ])
    }
  }, [
    currentSelected?.link,
    currentSelected?.link_occurence,
    currentSelected?.link_recovery
  ])

  useEffect(() => {
    if (listTabs.length) {
      setSelectedTabs(listTabs[0].value)
    }
  }, [listTabs])

  return (
    <>
      <Modal width={840} className="paddingless-modal">
        <div className="pt-10 px-[60px] pb-16">
          <Title title={t('building.titleAddAnnotation')} />
          <div className="flex items-center gap-5 -mt-5">
            <Typography className="text-[#021120] text-[14px] leading-[1]">
              {t('building.annotationType')}
            </Typography>
            <Select
              value={selectedType}
              onSelect={changeSelectedType}
              options={annotationList}
              suffixIcon={<DropdownIconCustom colorIcon="#000000" />}
              style={{
                width: 240,
                fontSize: 14,
                color: '#021120'
              }}
            />
          </div>
          <div className={`flex items-center ${annotationBgColor} mt-[10px]`}>
            {selectedCategory ? (
              <div
                className="cursor-pointer h-6 ml-5"
                onClick={handleCategoryBack}
              >
                <LeftArrowIcon size={24} />
              </div>
            ) : null}
            <div className="flex relative">
              {listTabs.map((item) => (
                <div
                  key={item.value}
                  className="flex w-20 h-[50px] cursor-pointer"
                  onClick={() => handleChangeTab(item.value)}
                >
                  <div className="text-base text-white m-auto">
                    {t(item.label)}
                  </div>
                </div>
              ))}
              <div
                className="absolute bottom-0 transition-all w-20 h-[5px] bg-[#A1C6B4]"
                style={{
                  left: `${selectedTabIndex * 80}px`
                }}
              />
            </div>
          </div>
          <div>
            {!selectedCategory ? (
              Const.BCP_ANNOTATION_TAB_CATEGORIES.map((item) => (
                <div
                  key={item.value}
                  className="flex items-center justify-between h-[60px] border-0 border-b border-solid border-[#d9d9d9] cursor-pointer"
                  onClick={() => setSelectedCategory(item.value)}
                >
                  <div className="text-base text-[#021120] font-medium ml-[10px]">
                    {t(item.label)}
                  </div>
                  <div className="mr-5">
                    <RightArrowIcon color="#021120" />
                  </div>
                </div>
              ))
            ) : (
              <BCPAnnotationCategoryDetail
                form={form}
                selectedType={selectedType}
                selectedTabs={selectedTabs}
                selectedTabIndex={selectedTabIndex}
                selectedCategory={selectedCategory}
                linkParse={linkParse}
                nameParse={nameParse}
                memoParse={memoParse}
                quill={quill}
                quillRef={quillRef}
                QuillClass={QuillClass}
                annotationBgColor={annotationBgColor}
              />
            )}
          </div>
          <div
            className={`flex pl-5 pr-[30px] ${
              selectedCategory
                ? 'mt-8 pt-5 border-0 border-t border-solid border-[#EEEFF2]'
                : 'mt-16'
            }`}
          >
            {currentSelected?.id ? (
              <Button
                className="bg-[#B80808]"
                sizing="w-[140px] h-[50px]"
                onClick={() => setIsDeleting(true)}
              >
                {t('common.delete')}
              </Button>
            ) : null}
            <div className="flex gap-10 ml-auto">
              <Button sizing="w-[140px] h-[50px]" onClick={handleCloseModal}>
                {t('common.cancel')}
              </Button>
              <Button
                type="primary"
                sizing="w-[140px] h-[50px]"
                loading={isLoading}
                onClick={handleAddOrEditAnnotation}
              >
                {t('common.confirm')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
      {isDeleting && (
        <BCPDeleteAnnotationModal
          handleCancelDelete={() => setIsDeleting(false)}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isChangeType && (
        <BCPConfirmChangeAnnotation
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          setIsOpenConfirm={setIsChangeType}
        />
      )}
    </>
  )
}
