import Icon from '@ant-design/icons'
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon'

function CopyUrlSvg() {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_23350_296804"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="19"
        height="19"
      >
        <path d="M19 0H0V19H19V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_23350_296804)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0581 2.96326C13.0581 2.26671 13.3029 1.59232 13.7498 1.05805C14.1968 0.52379 14.8173 0.163689 15.503 0.0407512C16.1886 -0.0821861 16.8956 0.0398681 17.5003 0.38556C18.105 0.731252 18.5689 1.27857 18.8109 1.93175C19.0528 2.58493 19.0573 3.30239 18.8237 3.95859C18.5901 4.6148 18.1332 5.16796 17.5329 5.52131C16.9326 5.87466 16.2273 6.00568 15.5401 5.89146C14.853 5.77724 14.2279 5.42505 13.7742 4.89651L5.79662 8.60149C5.98001 9.18273 5.98001 9.80633 5.79662 10.3875L13.7742 14.0925C14.2538 13.5348 14.9233 13.1749 15.6531 13.0827C16.3828 12.9905 17.1208 13.1725 17.7241 13.5934C18.3273 14.0143 18.7528 14.6442 18.9181 15.3609C19.0834 16.0777 18.9767 16.8302 18.6187 17.4728C18.2608 18.1154 17.677 18.6022 16.9806 18.8389C16.2842 19.0756 15.5247 19.0453 14.8493 18.7539C14.1738 18.4626 13.6307 17.9308 13.325 17.2618C13.0194 16.5927 12.9729 15.8341 13.1947 15.1327L5.21712 11.4277C4.82219 11.888 4.29579 12.2162 3.70873 12.3684C3.12166 12.5205 2.50209 12.4891 1.93337 12.2785C1.36464 12.068 0.87404 11.6884 0.527566 11.1906C0.181091 10.6928 -0.00463867 10.101 -0.00463867 9.49449C-0.00463867 8.88807 0.181091 8.29614 0.527566 7.79842C0.87404 7.30069 1.36464 6.921 1.93337 6.71044C2.50209 6.49988 3.12166 6.46856 3.70873 6.62066C4.29579 6.77277 4.82219 7.10103 5.21712 7.56126L13.1947 3.85626C13.1039 3.5673 13.0579 3.26615 13.0581 2.96326Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

export default function CopyUrlIcon(props: Partial<CustomIconComponentProps>) {
  return <Icon component={CopyUrlSvg} {...props} />
}
