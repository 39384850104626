import { RefObject, useCallback, useEffect, useState } from 'react'
import Quill from 'quill'
import QuillResizeImage from 'quill-resize-image'
import Krpano from 'utils/krpano'

import Spinner from 'components/atoms/Spinner'

type Props = {
  quill: Quill | undefined
  quillRef: RefObject<any>
  QuillClass: any
  memo: string | undefined
}

export default function MemoSetting({
  memo,
  QuillClass,
  quill,
  quillRef
}: Props) {
  const [isLoadingContent, setIsLoadingContent] = useState(false)

  const handleFetchContent = useCallback(async () => {
    if (memo && quill) {
      setIsLoadingContent(true)
      const textContent = await Krpano.FetchMemoContent(memo)
      quill.root.innerHTML = textContent
      setIsLoadingContent(false)
    }
  }, [memo, quill])

  useEffect(() => {
    if (QuillClass && !quill) {
      QuillClass.register('modules/resize', QuillResizeImage)
    }
  }, [QuillClass, quill])

  useEffect(() => {
    handleFetchContent()
  }, [handleFetchContent])

  return (
    <Spinner spinning={isLoadingContent}>
      <div className="pt-[30px] px-[90px] h-[350px] mb-10">
        <div
          ref={quillRef}
          className="quill-custom-editor overflow-hidden [&>#editor-resizer>.toolbar]:!hidden [&>.ql-editor]:pb-10"
        />
      </div>
    </Spinner>
  )
}
