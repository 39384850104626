import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Select, Tooltip, Typography } from 'antd'
import { Floor } from 'models/Building'
import { useRecoilState } from 'recoil'
import { floorDetailState } from 'store/buildingStore'
import currentViewerState from 'store/viewerStore'
import useSWR from 'swr'
import { FloorDetail, FloorMapElement } from 'types/building'
import Utils from 'utils'

import Spinner from 'components/atoms/Spinner'
import DropdownIconCustom from 'components/icons/DropdownIconCustom'

import ViewerAreaDropdown from './ViewerAreaDropdown'
import ViewerFloorMap from './ViewerFloorMap'

type Props = {
  floors: Floor[]
}

export default function ViewerFloorDetail({ floors }: Props) {
  const { t } = useTranslation()
  const { id } = useParams()
  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)
  const [floorDetail, setFloorDetail] = useRecoilState(floorDetailState)

  const { isValidating: isValidatingFloor, mutate: refetchFloor } =
    useSWR<FloorDetail>(
      currentViewer?.selectedFloor
        ? {
            url: `v1/floors/${currentViewer?.selectedFloor}`
          }
        : null,
      {
        onSuccess: (data) => {
          setFloorDetail(data)
        }
      }
    )

  const { data: mapItems, isValidating: isValidatingPoints } =
    useSWR<FloorMapElement>(
      currentViewer?.selectedFloor
        ? {
            url: 'v1/points',
            params: {
              buildingId: id,
              floorId: currentViewer?.selectedFloor
            }
          }
        : null,
      {
        onSuccess: (data) => {
          // prevent select default selected point when navigate
          if (currentViewer.selectedPoint) {
            setCurrentViewer((prev) => ({
              ...prev,
              selectedArea: `${data.points.find(
                (item) => item.id === currentViewer.selectedPoint
              )?.areaId}`
            }))
            return
          }

          const defaultPoint =
            data.defaultPoint ||
            data.points
              .filter((item) => item.image360)
              .reduce((acc, curr) => (curr.order < acc.order ? curr : acc))
          defaultPoint &&
            setCurrentViewer((prev) => ({
              ...prev,
              selectedArea: `${defaultPoint.areaId}`,
              selectedPoint: defaultPoint.id
            }))
        }
      }
    )

  const listFloor = floors.map((floor) => {
    const isTitle = floor.name.length > 21 ? floor.name : undefined
    return {
      value: floor.id,
      label: (
        <Tooltip placement="right" title={isTitle}>
          <span className="text-[#021120] text-sm">
            {Utils.truncateString(floor.name, 21)}
          </span>
        </Tooltip>
      )
    }
  })

  const handleSelectFloor = (value: number) => {
    setCurrentViewer((prev) => ({
      ...prev,
      selectedArea: undefined,
      selectedPoint: undefined,
      selectedFloor: value
    }))
  }

  return (
    <>
      <div className="flex flex-col gap-2 p-[10px]">
        <Typography className="font-bold text-white">
          {t('common.sidebar.floor')}
        </Typography>
        <Select
          style={{
            width: 220,
            overflow: 'hidden'
          }}
          options={listFloor}
          // TODO: update default floor after BE update
          value={currentViewer?.selectedFloor}
          onSelect={handleSelectFloor}
          disabled={!listFloor.length}
          className={!listFloor.length ? 'select-floot-disabled' : ''}
          suffixIcon={<DropdownIconCustom colorIcon="#021120" />}
        />
      </div>

      {!isValidatingFloor && !isValidatingPoints ? (
        <>
          <ViewerFloorMap
            imagePath={floorDetail?.mapFile?.s3Path}
            mapItems={mapItems}
            refetchFloor={refetchFloor}
          />

          <div className="h-full overflow-auto">
            <ViewerAreaDropdown
              areas={floorDetail?.areas || []}
              points={mapItems?.points || []}
            />
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  )
}
