import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { notification, Typography } from 'antd'
import useDirectory from 'hooks/useDirectory'
import { Building } from 'models/Building'
import { useRecoilValue } from 'recoil'
import {
  buildingModeState,
  selectedAreaState,
  selectedPoint
} from 'store/buildingStore'

import Link from 'components/atoms/Link'
import CopyUrlIcon from 'components/icons/CopyUrlIcon'
import PreviewIcon from 'components/icons/PreviewIcon'

import Sidebar from '../Sidebar'
import UserDropdown from '../UserDropdown'

type Props = {
  building?: Building
}

export default function HeaderDetailBuilding({ building }: Props) {
  const { t } = useTranslation()
  const { id, floorId } = useParams()
  const { getDirectoryBasename, logoHeader } = useDirectory()

  const currentSelectedPoint = useRecoilValue(selectedPoint)
  const buildingMode = useRecoilValue(buildingModeState)
  const selectedArea = useRecoilValue(selectedAreaState)

  const sessionParams = sessionStorage.getItem('buildingParams')

  const localStoringFloor = () => {
    floorId && localStorage.setItem('floor', floorId)
    window.open(`${getDirectoryBasename()}/viewer/buildings/${id}`, '_blank')
  }

  const handleCopyUrl = () => {
    let link = `${
      window.location.origin
    }${getDirectoryBasename()}/viewer/buildings/${id}`
    if (floorId) {
      link += `?floor=${floorId}`
    }
    if (selectedArea) {
      link += `&area=${selectedArea}`
    }
    if (currentSelectedPoint) {
      const krpano = document.getElementById('embedpano-full') as any
      const krpanoPreview = document.getElementById('embedpano-preview') as any
      const hlookat = buildingMode.previewMode
        ? krpano?.get('view.hlookat')
        : krpanoPreview?.get('view.hlookat')
      const vlookat = buildingMode.previewMode
        ? krpano?.get('view.vlookat')
        : krpanoPreview?.get('view.vlookat')
      const fov = buildingMode.previewMode
        ? krpano?.get('view.fov')
        : krpanoPreview?.get('view.fov')
      link += `&point=${currentSelectedPoint}&hlookat=${hlookat || 0}&vlookat=${
        vlookat || 0
      }&fov=${fov || 80}`
    }
    navigator.clipboard.writeText(link)
    notification.success({
      key: 'copy-successful',
      message: t('translation.copyUrlSuccessful')
    })
  }

  return (
    <div className="h-full grid grid-cols-3 items-center pl-5 theme-detail-building-header">
      <div className="flex items-center gap-2 select-none">
        <Sidebar />

        <Link to={sessionParams ? `/buildings?${sessionParams}` : '/buildings'}>
          <img
            src={logoHeader}
            alt="logo"
            className="max-w-[207px] cursor-pointer ml-[53px]"
          />
        </Link>
      </div>
      <div>
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: building?.organization?.name
          }}
          className="text-white text-center text-[14px] font-[500] truncate !mb-0"
        >
          {building?.organization?.name}
        </Typography.Paragraph>
        <Typography.Paragraph
          ellipsis={{
            rows: 1,
            tooltip: building?.name
          }}
          className="text-white text-center truncate font-[500] !mb-0"
        >
          {building?.name}
        </Typography.Paragraph>
      </div>
      <div
        className="relative h-full flex justify-end gap-6"
        style={{
          backgroundImage: "url('/assets/background_header.png')"
        }}
      >
        <div
          className="h-[35px] my-auto grid place-items-center cursor-pointer"
          onClick={handleCopyUrl}
        >
          <div className="flex items-center justify-center w-6 h-6">
            <CopyUrlIcon />
          </div>
          <Typography className=" text-center text-[11px] leading-[11px] font-[700] text-white">
            {t('common.header.copyUrl')}
          </Typography>
        </div>
        <div
          className="h-[35px] my-auto grid place-items-center cursor-pointer"
          onClick={localStoringFloor}
        >
          <div className="flex items-center justify-center w-6 h-6">
            <PreviewIcon />
          </div>
          <Typography className=" text-center text-[11px] leading-[11px] font-[700] text-white">
            {t('common.header.preview')}
          </Typography>
        </div>

        <UserDropdown t={t} />
      </div>
    </div>
  )
}

HeaderDetailBuilding.defaultProps = {
  building: undefined
}
