import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { notification, Typography } from 'antd'
import useDirectory from 'hooks/useDirectory'
import { Building } from 'models/Building'
import BuildingInformationModal from 'pages/building/buildingDetailViewMode/components/BuildingInformationModal'
import { useRecoilState } from 'recoil'
import { floorDetailState } from 'store/buildingStore'
import { currentPovState } from 'store/krpanoStore'
import currentViewerState from 'store/viewerStore'

import CopyUrlIcon from 'components/icons/CopyUrlIcon'
import FileIcon from 'components/icons/FileIcon'
import InformationIcon from 'components/icons/InformationIcon'
import LeftArrowIcon from 'components/icons/LeftArrowIcon'

import UserDropdown from '../UserDropdown'

type Props = {
  building?: Building
}

export default function ViewerHeader({ building }: Props) {
  const { t } = useTranslation()
  const { id } = useParams()
  const { pathname } = useLocation()
  const { rrdNavigate, navigate, logoHeader, getDirectoryBasename } =
    useDirectory()
  const [isOpenInfoPopup, setIsOpenInfoPopup] = useState(false)

  const [, setFloorDetail] = useRecoilState(floorDetailState)
  const [currentViewer, setCurrentViewer] = useRecoilState(currentViewerState)
  const [, setCurrentPov] = useRecoilState(currentPovState)

  const handleToggleInformationModal = () => {
    setIsOpenInfoPopup((prev) => !prev)
  }
  const backToPreviousBuilding = pathname.split('/').slice(0, -1).join('/')

  const handleBack = () => {
    if (pathname.includes('files')) {
      rrdNavigate(backToPreviousBuilding)
      return
    }
    setFloorDetail(null)
    setCurrentViewer({
      selectedFloor: undefined,
      selectedPoint: undefined,
      selectedArea: undefined,
      isOpenLeftMenu: true,
      isOpenMiniMap: false
    })
    navigate('/buildings')
  }

  const handleSaveCurrentView = () => {
    const krpano = document.getElementById('embedpano-viewer') as any
    if (krpano) {
      const h = krpano.get('view.hlookat')
      const v = krpano.get('view.vlookat')
      const z = krpano.get('view.fov')
      setCurrentPov({ h, v, z })
    }
  }

  const handleNavigate = () => {
    if (pathname.includes('files')) {
      navigate(`/viewer/buildings/${id}`)
      return
    }
    handleSaveCurrentView()
    rrdNavigate('files')
  }

  const handleCopyUrl = () => {
    let link = `${
      window.location.origin
    }${getDirectoryBasename()}/viewer/buildings/${id}`
    if (currentViewer.selectedFloor) {
      link += `?floor=${currentViewer.selectedFloor}`
    }
    if (currentViewer.selectedArea) {
      link += `&area=${currentViewer.selectedArea}`
    }
    if (currentViewer.selectedPoint) {
      const krpano = document.getElementById('embedpano-viewer') as any
      const hlookat = krpano?.get('view.hlookat')
      const vlookat = krpano?.get('view.vlookat')
      const fov = krpano?.get('view.fov')
      link += `&point=${currentViewer.selectedPoint}&hlookat=${
        hlookat || 0
      }&vlookat=${vlookat || 0}&fov=${fov || 80}`
    }
    navigator.clipboard.writeText(link)
    notification.success({
      key: 'copy-successful',
      message: t('translation.copyUrlSuccessful')
    })
  }

  return (
    <>
      <div className="h-[60px] grid grid-cols-3 items-center pl-5 theme-viewer-header">
        <div
          className="flex items-center gap-8 select-none cursor-pointer"
          onClick={handleBack}
        >
          <LeftArrowIcon size={20} />
          <img src={logoHeader} alt="logo" className="max-w-[207px]" />
        </div>

        <div>
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              tooltip: building?.organization?.name
            }}
            className="text-white text-center text-[14px] font-[500] truncate !mb-0"
          >
            {building?.organization?.name}
          </Typography.Paragraph>
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              tooltip: building?.name
            }}
            className="text-white text-center truncate font-[500] !mb-0"
          >
            {building?.name}
          </Typography.Paragraph>
        </div>
        <div
          className="relative h-full flex justify-end gap-6"
          style={{
            backgroundImage: "url('/assets/background_header.png')"
          }}
        >
          <div
            className="h-[35px] my-auto flex flex-col items-center justify-between cursor-pointer"
            onClick={handleCopyUrl}
          >
            <div className="flex items-center justify-center w-6 h-6">
              <CopyUrlIcon />
            </div>
            <Typography className=" text-center text-[11px] leading-[11px] font-[700] text-white">
              {t('common.header.copyUrl')}
            </Typography>
          </div>
          <div
            className="h-[35px] my-auto flex flex-col items-center justify-between cursor-pointer"
            onClick={handleToggleInformationModal}
          >
            <div className="flex items-center justify-center w-6 h-6">
              <InformationIcon />
            </div>
            <Typography className=" text-center text-[11px] leading-[11px] font-[700] text-white">
              {t('common.header.information')}
            </Typography>
          </div>
          <div
            className="h-[35px] flex flex-col items-center justify-between my-auto cursor-pointer"
            onClick={handleNavigate}
          >
            <div className="flex items-center justify-center w-6 h-6">
              <FileIcon isActive={pathname.includes('files')} />
            </div>
            <Typography
              className={`text-center text-[11px] leading-[11px] font-[700] ${
                pathname.includes('files')
                  ? 'theme-text-sub-primary'
                  : 'text-white'
              }`}
            >
              {t('common.header.file')}
            </Typography>
          </div>
          <UserDropdown t={t} />
        </div>
      </div>
      {isOpenInfoPopup && (
        <BuildingInformationModal
          building={building}
          handleToggle={handleToggleInformationModal}
        />
      )}
    </>
  )
}

ViewerHeader.defaultProps = {
  building: undefined
}
