import { Annotation, AnnotationType } from 'types/building'
import { Direction, Stairway } from 'types/krpano'

const Krpano = class Krpano {
  static AddDirectionArrow = (
    krpano: any,
    directions: Direction[],
    hgap: number
  ) => {
    const addingHgap = hgap > 180 ? 360 - hgap : hgap
    directions.forEach((direction) => {
      krpano.call(
        `add_direction(${direction.id},${direction.h + addingHgap},${
          direction.v
        },${direction.target},${direction.point_to})`
      )
    })
  }

  static AddAnnotation = (krpano: any, annotations: Annotation[]) => {
    annotations.forEach(async (annotation) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { id, head_h, head_v, mid_h, mid_v, h, v, type, memo } = {
        ...annotation
      }
      const annotationType = Object.values(AnnotationType).includes(type || '')
        ? type
        : AnnotationType.TRADITION

      const textContent = await this.FetchMemoContent(memo || '')

      krpano.call(
        `add_annotation(${id},${head_h},${head_v},${mid_h},${mid_v},${h},${v},${annotationType},<div class="ql-editor p-0">${textContent}</div>,${annotation.set_annotation_id});`
      )
    })
  }

  static UnmountPanoFull = () => {
    const krpano = document.getElementById('embedpano-full') as any
    if (krpano) {
      ;(window as any).removepano('embedpano-full')
    }
  }

  static UnmountPanoPreview = () => {
    const krpano = document.getElementById('embedpano-preview') as any
    if (krpano) {
      ;(window as any).removepano('embedpano-preview')
    }
  }

  static UnmountPanoViewer = () => {
    const krpano = document.getElementById('embedpano-viewer') as any
    if (krpano) {
      ;(window as any).removepano('embedpano-viewer')
    }
  }

  static RemoveTempAnnotation = () => {
    const krpano = document.getElementById('embedpano-full') as any
    krpano?.call('hide_temp_hotspot();')
  }

  static UnmountFull = () => {
    this.UnmountPanoFull()
    this.UnmountPanoPreview()
  }

  static GetAngle = (dx: any, dy: any) => {
    const d = (Math.atan2(dy, dx) * 180) / Math.PI
    if (d < 0) {
      return d + 360
    }
    return d
  }

  static TogglePointLock = (isLocked?: boolean) => {
    const krpano = document.getElementById('embedpano-full') as any
    krpano?.call(`set(islocked,${isLocked || false});`)
  }

  static LoadChecking = (callback: () => void) => {
    const fullpanoLoading = (
      document.getElementById('embedpano-full') as any
    )?.get('loadcomplete')
    const previewpanoLoading = (
      document.getElementById('embedpano-preview') as any
    )?.get('loadcomplete')
    if (
      (fullpanoLoading === undefined && previewpanoLoading === undefined) ||
      (fullpanoLoading === 'true' && previewpanoLoading === 'true')
    ) {
      callback()
    }
  }

  static NormalizeHgap = (hgap: number): number => {
    if (hgap >= 360) {
      return this.NormalizeHgap(hgap - 360)
    }
    if (hgap < 0) {
      return this.NormalizeHgap(hgap + 360)
    }
    return hgap
  }

  static FormatStairName = (name?: string) => {
    const chars: any = {
      "'": '&apos;',
      '"': '&quot;'
    }
    return (
      name
        ? `<p>フロア：${
            name.length > 10 ? `${name?.slice(0, 10)}...` : name
          }</p>`
        : ''
    ).replace(/['"]/g, (m) => chars[m])
  }

  static AddStairway = (krpano: any, stairways: Stairway[]) => {
    if (krpano) {
      stairways.forEach((item) => {
        const newTooltip = this.FormatStairName(item.nextFloorName)
        krpano.call(
          `add_stairway(${item.id}, ${item.h}, ${item.v}, ${item.nextFloorId}, ${item.nextPointId}, ${newTooltip})`
        )
      })
    }
  }

  static RevalidateDirection = (directions: Direction[], hgap: number) => {
    const krpano = document.getElementById('embedpano-full') as any

    const addingHgap = hgap > 180 ? 360 - hgap : hgap
    directions.forEach((item) => {
      krpano.call(`set(hotspot[arrow-${item.id}].ath, ${item.h + addingHgap});`)
      krpano.call(
        `set(hotspot[tooltip-${item.id}].ath, ${item.h + addingHgap});`
      )
    })
  }

  static FetchMemoContent = async (s3Link: string) => {
    // is valid digitori360 s3 link
    if (s3Link.startsWith('https://digitori360')) {
      const response = await fetch(s3Link)
      const blob = await response.blob()
      const textContent = await blob.text()
      return textContent
    }
    const entities: any = {
      '/': '&#x2F;',
      '\n': '<br/>',
      ',': '&#x2C;',
      '<': '&lt;',
      '>': '&gt;'
    }
    const textContent = s3Link
      .split('')
      .map((item: any) => (entities[item] ? entities[item] : item))
      .join('')
    return textContent
  }

  static IsMemoEmpty(value: string) {
    if (
      value.replace(/<(.|\n)*?>/g, '').trim().length === 0 &&
      !value.includes('<img')
    ) {
      return true
    }
    return false
  }

  static GetMemoKeyFromPresignedUrl(presignedUrl: string) {
    const startInd = presignedUrl.indexOf('/assets')
    const endIdx = presignedUrl.indexOf('.txt')
    const key = presignedUrl.substring(startInd, endIdx + 5)
    return key
  }
}

export default Krpano
