import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { List } from 'antd'
import useDirectory from 'hooks/useDirectory'
import useMutation from 'hooks/useMutation'
import { Building } from 'models/Building'
import CustomCheckBox from 'pages/building/floor/components/CustomCheckBox'
import useSWR from 'swr'

import Button from 'components/atoms/Button'
import Modal from 'components/atoms/Modal'
import Spinner from 'components/atoms/Spinner'
import Title from 'components/atoms/Title'

type Props = {
  handleCancel: () => void
}

export default function ModalSettingDefaultFloor({ handleCancel }: Props) {
  const { id } = useParams()
  const { t } = useTranslation()

  const { getBuiildingType } = useDirectory()

  const [selectedFloor, setSelectedFloor] = useState<number>()

  const {
    data: building,
    mutate: refetchBuilding,
    isValidating
  } = useSWR<Building>(
    {
      url: `/v1/buildings/by-type?id=${id}&building_type=${getBuiildingType()}`
    },
    {
      revalidateOnMount: false
    }
  )

  const floors = useMemo(() => building?.floors || [], [building?.floors])

  const { trigger, isMutating } = useMutation(
    `v1/buildings/${id}/update-default-floor`
  )

  const handleSubmit = () => {
    if (!isMutating) {
      trigger(
        {
          method: 'patch',
          data: {
            defaultFloor: selectedFloor
          },
          successMessage: t('building.saved')
        },
        {
          onSuccess: () => {
            refetchBuilding()
            handleCancel()
          }
        }
      )
    }
  }

  return (
    <Modal
      className="modal-custom model-custom-default-img"
      width={506}
      rootClassName=""
    >
      <Title title={t('building.setDefaultFloor')} bottomMargin="mb-[31px]" />
      {isValidating ? (
        <Spinner>
          <div className="min-h-[470px]" />
        </Spinner>
      ) : (
        <List
          dataSource={floors}
          style={{
            height: 470,
            overflowY: 'auto',
            padding: '0 20px 0 60px'
          }}
          renderItem={(item) => (
            <List.Item
              className="!py-0 mb-[10px] cursor-pointer"
              onClick={() => setSelectedFloor(item.id)}
              style={{ borderBlockEnd: 'none' }}
            >
              <CustomCheckBox
                id={item.id}
                isDefault={building?.defaultFloor === item.id}
                checked={selectedFloor}
              />
              <div className="ml-5 mr-auto w-[250px] text-[12px] leading-[12px] font-bold text-[#0B2E5F]">
                {item.name}
              </div>
            </List.Item>
          )}
        />
      )}
      <div className="flex gap-10 justify-center mt-10">
        <Button sizing="w-[140px] h-[50px]" onClick={handleCancel}>
          {t('building.buttonCancel')}
        </Button>

        <Button
          onClick={handleSubmit}
          type="primary"
          sizing="w-[140px] h-[50px]"
          disabled={!selectedFloor}
        >
          {t('building.buttonSave')}
        </Button>
      </div>
    </Modal>
  )
}
